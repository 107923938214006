import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EditAppointmentService } from './edit-appointment.service';

@Component({
	selector: 'app-edit-appointment',
	templateUrl: './edit-appointment.component.html'
})
export class EditAppointmentComponent extends iBisBaseComponent {

	public declare edit: EditAppointmentService;

	constructor(public proxy: ProxyService) {
		super(proxy.appointment, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	openPatient = () => {
		this.proxy.patient.showPopup(null).then((res: any) => {
			if (res) {
				this.proxy.patient.filter = false;
				this.proxy.patient.reloadData();
				this.proxy.appointment.model.patientId = res.id;
			}
		});
	}

	public customPatientDisplay = (row: any): string => {
		return row ? row.lastName + ' ' + row.firstName + (row.cnp ? (' - ' + row.cnp.substring(0, 7) + '...') : '') : null;
	}

	public displayPatientAppointment = (row: any): string => {
		if (row) {
			const startDate = new Date(row.startDate);
			const endDate = new Date(row.endDate);
			return this.edit.getHoursMinutes(startDate) + ' - ' + this.edit.getHoursMinutes(endDate) + ' ' + row.patientName;
		}
		return '';
	}

	onDateChanged = (event: any) => {
		if (this.edit.model.userId) {
			this.edit.refreshAppointmentsByDateAndDoctor();
			this.edit.model.startTime = null;
			this.edit.model.endTime = null;
		}
	}

	onDoctorChanged = (event: any) => {
		if (this.edit.model.startDate) {
			this.edit.refreshAppointmentsByDateAndDoctor();
			this.edit.model.startTime = null;
			this.edit.model.endTime = null;
		}
	}


	onStartTimeChanged = (ev: any) => {
		if (ev.event) {
			// Calculăm endTime (startTime + 20 min)
			const [startHour, startMinute] = ev.value.split(':').map(Number);
			const startTimeInMinutes = startHour * 60 + startMinute;
			const endTimeInMinutes = startTimeInMinutes + 20;
			const endHour = Math.floor(endTimeInMinutes / 60);
			const endMinute = endTimeInMinutes % 60;
			const formattedEndTime = ('0' + endHour).slice(-2) + ':' + ('0' + endMinute).slice(-2);

			this.edit.model.endTime = formattedEndTime;

			this.edit.getEndTimes();
		}
	}

	// TODO: To disable the dates of the curent Clinic(party) which can be now in EditPartyWorkingSchedule
	// TODO: Check if we can move this functionality in that service (editParty or EditPartyWorkingSchedule)
	disabledDates = (e: any) => {
		if (e.view === 'month') {
			this.disabledDates = (data) => {
				return data.view === 'month' && new Date(data.date).getDay() == 0;
			};
		} else {
			this.disabledDates = undefined;
		}
	}

	disabledStartDates = (e: any) => {
		if (e.view === 'hour') {
			this.disabledStartDates = (data) => {
				return data.view === 'hour' && new Date(data.date).getHours() > 14;
			}
		}
	}

	onCustomItemCreating = (e: any) => {
		const customTime = e.text;
		e.customItem = { displayValue: customTime, value: customTime };
	}
}
