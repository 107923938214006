// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-box{
    display: flex;
}
.list-box{
    width: 300px;
    margin: 10px;
    border: 1px solid #ccc;
    background-color: white;
}
.message-box{
    width: calc(100% - 300px);
    margin: 10px;
    border: 1px solid #ccc;
    border-bottom: 0 solid;
    background-color: white;
}
.message-header{
    display: flex;
    justify-content: space-between;
}
.message-search{
    margin:16px 0;
}
.message-title h2{
    display: inline-block;
    margin-left: 20px
}
.message-body{
    padding: 10px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.is-received{
    text-align:right;
}
.message-item{
    padding: 5px;
    margin: 5px;
}
.message-text{
    white-space: normal;
}
.text-right{
    margin: 10px 0px 10px 80px;
}
.text-left{
    margin: 10px 80px 10px 0px;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    border-radius: 50%;
    display: inline-block;
  }`, "",{"version":3,"sources":["webpack://./src/app/view/message/message.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,aAAa;AACjB;AACA;IACI,qBAAqB;IACrB;AACJ;AACA;IACI,eAAe;IACf,0BAA0B;IAC1B,6BAA6B;AACjC;AACA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,0BAA0B;AAC9B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,qBAAqB;EACvB","sourcesContent":[".chat-box{\n    display: flex;\n}\n.list-box{\n    width: 300px;\n    margin: 10px;\n    border: 1px solid #ccc;\n    background-color: white;\n}\n.message-box{\n    width: calc(100% - 300px);\n    margin: 10px;\n    border: 1px solid #ccc;\n    border-bottom: 0 solid;\n    background-color: white;\n}\n.message-header{\n    display: flex;\n    justify-content: space-between;\n}\n.message-search{\n    margin:16px 0;\n}\n.message-title h2{\n    display: inline-block;\n    margin-left: 20px\n}\n.message-body{\n    padding: 10px 0;\n    border-top: 1px solid #ccc;\n    border-bottom: 1px solid #ccc;\n}\n.is-received{\n    text-align:right;\n}\n.message-item{\n    padding: 5px;\n    margin: 5px;\n}\n.message-text{\n    white-space: normal;\n}\n.text-right{\n    margin: 10px 0px 10px 80px;\n}\n.text-left{\n    margin: 10px 80px 10px 0px;\n}\n\n.dot {\n    height: 10px;\n    width: 10px;\n    margin: 0 5px;\n    border-radius: 50%;\n    display: inline-block;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
