// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.file-upload-box{
    border: 2px dashed #b0b0b0;
    margin: 10px 0px;
}

.file-compact{
    width: 150px
}

.file-img-txt{
    cursor: pointer;
    position:absolute;
    top: 10px;
    left: 7px;
    text-decoration: none;
    color: #fff;
    width: 137px;
    word-wrap: break-word;
}
.file-preview-box{
    position:relative;
    width:150px; 
    height: 150px;
    margin: 0 15px;
}

.bk-on-hover{
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#000000c2, #00000000);
    position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/app/common/file-upload/file-upload.component.css"],"names":[],"mappings":";AACA;IACI,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI;AACJ;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,SAAS;IACT,SAAS;IACT,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;AACA;IACI,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uDAAuD;IACvD,kBAAkB;AACtB","sourcesContent":["\r\n.file-upload-box{\r\n    border: 2px dashed #b0b0b0;\r\n    margin: 10px 0px;\r\n}\r\n\r\n.file-compact{\r\n    width: 150px\r\n}\r\n\r\n.file-img-txt{\r\n    cursor: pointer;\r\n    position:absolute;\r\n    top: 10px;\r\n    left: 7px;\r\n    text-decoration: none;\r\n    color: #fff;\r\n    width: 137px;\r\n    word-wrap: break-word;\r\n}\r\n.file-preview-box{\r\n    position:relative;\r\n    width:150px; \r\n    height: 150px;\r\n    margin: 0 15px;\r\n}\r\n\r\n.bk-on-hover{\r\n    width: 100%;\r\n    height: 100%;\r\n    background-image: linear-gradient(#000000c2, #00000000);\r\n    position: absolute;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
