import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import { iBisAuthService, iBisEditUserService } from '@dohu/ibis-auth';
import { iBisFileService, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { iBisNavigationService } from '@dohu/ibis-navigation';
import { DataService } from './data.service';
import { iBisEntityService } from '@dohu/ibis-entity';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthDetailsService } from '../common/auth-details/auth-details.service';
import { iBisNotifyService } from '@dohu/ibis-ws';

import { EditGeographicAreaService } from '../view/admin/geographic-area/edit-geographic-area.service';
import { EditPatientService } from '../view/admin/patient/edit-patient.service';
import { EditSendSmsService } from '../view/admin/sms-queue/send-sms/send-sms.service';
import { EditMaintenanceService } from '../view/admin/maintenance/edit-maintenance.service';
import { EditSpecializationService } from '../view/admin/specialization/edit-specialization.service';
import { EditProcedureService } from '../view/admin/procedure/edit-procedure.service';
import { EditPartySpecializationService } from '../view/admin/company-details/edit-party-specialization/edit-party-specialization.service';
import { EditConsultationService } from '../view/scheduler/calendar/edit-consultation/edit-consultation.service';
import { PatientHistoryService } from '../view/scheduler/calendar/patient-history/patient-history.service';
import { SendScheduleService } from '../view/scheduler/calendar/send-schedule/send-schedule.service';
import { OperationRoomService } from '../view/scheduler/operation-room/operation-room.service';
import { CalendarService } from '../view/scheduler/calendar/calendar.service';
import { environment } from '../../environments/environment';
import { EditDiagnosticService } from '../view/admin/diagnostic/edit-diagnostic.service';
import { EditAppointmentService } from '../view/scheduler/appointment/edit-appointment.service';
import { EditCompanyService } from '../view/admin/company-details/edit-company/edit-company.service';
import { EditDoctorService } from '../view/admin/doctor/edit-doctor.service';
import { EditPartyOperatingRoomService } from '../view/admin/company-details/edit-party-operating-room/edit-party-operating-room.service';

@Injectable()
export class ProxyService {

	public environment = environment;
	constructor(public auth: iBisAuthService, public lg: iBisLanguageService, public iui: iBisUIService, public ds: DataService,
		public user: iBisEditUserService, public nav: iBisNavigationService, public activatedRoute: ActivatedRoute, public router: Router,
		public file: iBisFileService, public entity: iBisEntityService, public iBisNotify: iBisNotifyService,
		public sendSchedule: SendScheduleService, public calendar: CalendarService,
		public patientHistory: PatientHistoryService, public appointment: EditAppointmentService, public operationRoom: OperationRoomService,
		public consultation: EditConsultationService, public geographicArea: EditGeographicAreaService, public patient: EditPatientService,
		public partySpec: EditPartySpecializationService, public sendSms: EditSendSmsService, public maintenance: EditMaintenanceService,
		public specialization: EditSpecializationService, public procedure: EditProcedureService, public authDetails: AuthDetailsService,
		public diagnostic: EditDiagnosticService, public company: EditCompanyService, public doctor: EditDoctorService, 
		public partyOperatingRoom: EditPartyOperatingRoomService) {
		nav.onShowPopup.subscribe((item: any) => {
			switch (item) {
				case 'user-role-config':
					this.authDetails.showPopup(true, true);
					break;
			}
		});

		this.ds.authDetails = localStorage.getItem('authDetails') ? JSON.parse(localStorage.getItem('authDetails')) : this.ds.authDetails;
		this.auth.displayRole = this.ds.authDetails.role.name;

		this.entity.onError.subscribe(error => {
			if (error.status === 401) {
				// auth.logout();
				notify('Authorization has expired. Retry to login.', 'error', 3000);
				this.auth.reset();
				this.router.navigateByUrl('/login', { skipLocationChange: true });
			}
		});
	}

	public enumDisplay(info: any) {
		return info ? info.code + ' - ' + (info.description || '') : '';
	}

	public convertToDate(date: Date): Date {
		return new Date(date.setTime(date.getTime() + (date.getTimezoneOffset() / -60) * 60 * 60 * 1000));
	}

	public get allowEdit(): boolean {
		return this.auth.tabAccess.edit;
	}

	public get allowDelete(): boolean {
		return this.auth.tabAccess.delete;
	}

	public get showActions(): boolean {
		return this.allowEdit || this.allowDelete;
	}

	public get allowedActions(): number {
		return (this.allowEdit ? 1 : 0) + (this.allowDelete ? 1 : 0);
	}

	public get actionsWidth(): number {
		return this.allowedActions * (this.iui.config.ui.actionWidth ?? 35);
	}
}
