// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  height: calc(100vh - 210px);
  width: 100%;
  background: #fff;
  overflow-y: scroll;
  padding: 5px;
}
.terms-container .term-item {
  border: 1px solid #253648;
  border-radius: 8px;
  margin: 5px;
  padding: 15px;
  position: relative;
}
.terms-container .term-item .term-description {
  font-size: 14px;
}
.terms-container .term-item .term-title {
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
}

.terms-container::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/view/account/terms/terms.component.scss"],"names":[],"mappings":"AACA;EACI,wBAAA,EAAA,0BAAA;EACA,qBAAA,EAAA,YAAA;EACA,2BAAA;EAEA,WAAA;EAEA,gBAAA;EACA,kBAAA;EACA,YAAA;AAFJ;AAII;EACI,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AAFR;AAIQ;EACI,eAAA;AAFZ;AAKQ;EACI,eAAA;EACA,gBAAA;EACA,YAAA;AAHZ;;AAQA;EACI,aAAA;AALJ","sourcesContent":["\n.terms-container {\n    -ms-overflow-style: none;  /* Internet Explorer 10+ */\n    scrollbar-width: none;  /* Firefox */\n    height: calc(100vh - 210px);\n    // width: calc(100vw - 40px);\n    width: 100%;\n    // margin: 0 auto;\n    background: #fff;\n    overflow-y: scroll;\n    padding: 5px;\n\n    .term-item {\n        border: 1px solid #253648;\n        border-radius: 8px;\n        margin: 5px;\n        padding: 15px;\n        position: relative;\n\n        .term-description {\n            font-size: 14px;\n        }\n    \n        .term-title {\n            font-size: 16px;\n            font-weight: 600;\n            padding: 5px;\n        }\n    }\n}\n\n.terms-container::-webkit-scrollbar {\n    display: none;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
