import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EditMaintenanceService } from './edit-maintenance.service';

@Component({
	selector: 'app-edit-maintenance',
	templateUrl: './edit-maintenance.component.html'
})
export class EditMaintenanceComponent extends iBisBaseComponent {

	public declare edit: EditMaintenanceService;
	constructor(public proxy: ProxyService) {
		super(proxy.maintenance, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
