import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisEntityService } from '@dohu/ibis-entity';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EditPartyDataService extends iBisSecureEditService {

    public selectedIndex: number = -1;

    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
        super(auth, entity, config, lg);
    }

    protected load(): void {
    }

    createDefault() {
        return {};
    }
    getById(id: string): Promise<any> {
        throw new Error('Method not implemented.');
    }
    onRemove(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    onSaveEv(): Promise<any> {
        throw new Error('Method not implemented.');
    }
    reset(): void {
        this.model = this.createDefault();
    }
}
