// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.facility-list {
  border-bottom: 1px solid rgb(221, 221, 221);
}`, "",{"version":3,"sources":["webpack://./src/app/view/admin/company-details/party-list/party-list.component.scss"],"names":[],"mappings":"AAAA;EACI,2CAAA;AACJ","sourcesContent":[".facility-list {\n    border-bottom: 1px solid rgba(221, 221, 221, 1);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
