import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { iBisAuthModule } from '@dohu/ibis-auth';
import { iBisCommonModule } from '@dohu/ibis-common';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { SchedulerComponent } from './scheduler.component';
import { CommonAppModule } from '../../common/common.module';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { CalendarService } from './calendar/calendar.service';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { CalendarComponent } from './calendar/calendar.component';
import { DxCalendarModule } from 'devextreme-angular/ui/calendar';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxAutocompleteModule } from 'devextreme-angular/ui/autocomplete';
import { AppointmentComponent } from './appointment/appointment.component';
import { EditAppointmentService } from './appointment/edit-appointment.service';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxSpeedDialActionModule } from 'devextreme-angular/ui/speed-dial-action';
import { OperationRoomComponent } from './operation-room/operation-room.component';
import { EditAppointmentComponent } from './appointment/edit-appointment.component';
import { SendScheduleService } from './calendar/send-schedule/send-schedule.service';
import { SendScheduleComponent } from './calendar/send-schedule/send-schedule.component';
import { PatientHistoryService } from './calendar/patient-history/patient-history.service';
import { OperationRoomService } from './operation-room/operation-room.service';
import { PatientHistoryComponent } from './calendar/patient-history/patient-history.component';
import { EditConsultationService } from './calendar/edit-consultation/edit-consultation.service';
import { EditConsultationComponent } from './calendar/edit-consultation/edit-consultation.component';
import { AdminModule } from '../admin/admin.module';

@NgModule({
	declarations: [
		CalendarComponent,
		SchedulerComponent,
		AppointmentComponent,
		SendScheduleComponent,
		OperationRoomComponent,
		PatientHistoryComponent,
		EditAppointmentComponent,
		EditConsultationComponent,
	],
	imports: [
		AdminModule,
		RouterModule,
		DxFormModule,
		DxTabsModule,
		DxFormModule,
		DxListModule,
		BrowserModule,
		DxSwitchModule,
		DxButtonModule,
		iBisAuthModule,
		DxTagBoxModule,
		CommonAppModule,
		DxToolbarModule,
		DxPopoverModule,
		DxTextBoxModule,
		DxTabPanelModule,
		DxCalendarModule,
		iBisCommonModule,
		DxDataGridModule,
		DxSchedulerModule,
		DxNumberBoxModule,
		DxValidatorModule,
		DxSelectBoxModule,
		DxScrollViewModule,
		DxAutocompleteModule,
		DxValidationGroupModule,
		DxSpeedDialActionModule,
	],
	exports: [
		CalendarComponent,
		SchedulerComponent,
		SendScheduleComponent,
		OperationRoomComponent,
		PatientHistoryComponent,
		EditAppointmentComponent,
		EditConsultationComponent,
	],
	providers: [
		CalendarService,
		SendScheduleService,
		OperationRoomService,
		PatientHistoryService,
		EditAppointmentService,
		EditConsultationService,
	]
})
export class SchedulerModule { }
