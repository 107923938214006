import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-company-details',
	templateUrl: './company-details.component.html',
	styleUrls: ['./company-details.component.scss']
})

export class CompanyDetailsComponent extends iBisBaseComponent {

	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
