import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { AuthDetailsService } from './auth-details.service';

@Component({
  selector: 'app-auth-details',
  templateUrl: './auth-details.component.html'
})
export class AuthDetailsComponent extends iBisBaseComponent {

  constructor(public edit: AuthDetailsService, public proxy: ProxyService) {
    super(edit, proxy.lg, proxy.iui, proxy.activatedRoute);
  }

}
