import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { iBisAuthModule } from '@dohu/ibis-auth';
import { iBisCommonModule } from '@dohu/ibis-common';
import { BrowserModule } from '@angular/platform-browser';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { ConsultationReportComponent } from './consultation-report/consultation-report.component';
import { EditConsultationReportService } from './consultation-report/edit-consultation-report.service';

@NgModule({
	declarations: [
		ConsultationReportComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
		iBisAuthModule,
		DxDataGridModule,
		iBisCommonModule,
	],
	exports: [
		ConsultationReportComponent
	],
	providers: [
		EditConsultationReportService
	]
})
export class ReportModule { }
