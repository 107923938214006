
import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component } from '@angular/core';
import { ProxyService } from '../../../../service/proxy.service';
import { EditPartyListService } from '../party-list/edit-party-list.service';
import { EditPartyOperatingRoomService } from './edit-party-operating-room.service';


@Component({
    selector: 'app-edit-party-operating-room',
    templateUrl: './edit-party-operating-room.component.html'
})

export class EditPartyOperatingRoomComponent extends iBisBaseComponent {

    constructor(public proxy: ProxyService, public edit: EditPartyOperatingRoomService, private partyList: EditPartyListService) {
        super(null, proxy.lg, proxy.iui, proxy.activatedRoute);

        this.partyList.onDataChanged.subscribe((data) => {
            if (data === 'party-selected') {
                this.edit.partyId = this.partyList.partySelected;
            }
        });
    }

    public onNewOperatingRoom(event: any) {
        event.data.partyId = this.edit.partyId;
    }
}