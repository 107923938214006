import { Injectable } from '@angular/core';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class EditConsultationReportService extends iBisSecureEditService {

	public data: any;
	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
		super(auth, entity, config, lg);
	}
	protected load(): void {
		this.getData();
	}
	createDefault() {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): void {
		this.entity.load(new EntityQuery('ConsultationReportView').addOrderByDesc(['dateMonth'])).then((result: any) => {
			this.data = result;
		});
	}
}
