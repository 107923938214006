import { DxListComponent } from 'devextreme-angular/ui/list';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { DataService } from '../../service/data.service';
import { ProxyService } from '../../service/proxy.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'app-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.css']
})
export class MessageComponent extends iBisBaseComponent implements OnInit {
	dsMessage: any[] = [];
	dsMembers: any[] = [];
	chatUser: any;

	@ViewChild('messListInst') messListInst: DxListComponent;
	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	ngOnInit(): void {
		this.dsMessage = this.getMessage();
		this.dsMembers = this.getMembers();
		this.chatUser = this.dsMembers[0];
	}

	onPressEnter(ev: any) {
		const value = ev.component.option('value');
		const obj = { text: value, time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 };
		this.dsMessage.push(obj);
		this.messListInst.instance.scrollToItem(obj);
		ev.component.option('value', '');
	}

	onMemberSelected(ev: any) {
		this.chatUser = ev.itemData;
	}

	onHistorySearch(ev: any) {
		this.messListInst.instance.option('searchValue', ev.value);
	}

	// TODO: What is this?
	getMembers(): any {
		return [{ name: 'Daviduta', statusId: 1 },
		{ name: 'Emanuel', statusId: 0 },
		{ name: 'Daviduta', statusId: 1 },
		{ name: 'Emanuel', statusId: 0 },
		{ name: 'Daviduta', statusId: 1 },
		{ name: 'Emanuel', statusId: 0 },
		{ name: 'Daviduta', statusId: 1 },
		{ name: 'Emanuel', statusId: 0 },
		{ name: 'Daviduta', statusId: 1 },
		{ name: 'Emanuel', statusId: 0 }];
	}

	// TODO: What is this?
	getMessage(): any {
		return [{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 0 },
		{ text: 'Abra cadabra', time: this.proxy.entity.toDateTimeFilter(new Date()), state: 1 }];
	}


}
