// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-body {
  width: 300px;
  margin: 0px auto;
  padding-top: 20px;
}

.accountContainer {
  height: calc(100vh - 156px);
}

.user-symbol {
  width: 100px;
  height: 100px;
  background: #1aa1bd;
  border-radius: 60px;
  display: inline-block;
  color: white;
  padding: 0px;
  vertical-align: middle;
  margin: 5px 7px;
}

.symbol-text {
  text-align: center;
  font-size: 29px;
}`, "",{"version":3,"sources":["webpack://./src/app/view/account/user-profile/user-profile.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,2BAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,qBAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;AACJ;;AACA;EACI,kBAAA;EACA,eAAA;AAEJ","sourcesContent":[".account-body {\n    width: 300px;\n    margin: 0px auto;\n    padding-top: 20px;\n}\n\n.accountContainer {\n    height: calc(100vh - 156px);\n}\n\n.user-symbol {\n    width: 100px;\n    height: 100px;\n    background: #1aa1bd;\n    border-radius: 60px;\n    display: inline-block;\n    color: white;\n    padding: 0px;\n    vertical-align: middle;\n    margin: 5px 7px;\n}\n.symbol-text{\n    text-align: center;\n    font-size: 29px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
