import { Component } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../../service/proxy.service';
import { EditPartySpecializationService } from './edit-party-specialization.service';

@Component({
    selector: 'app-edit-party-specialization',
    templateUrl: './edit-party-specialization.component.html'
})
export class EditPartySpecializationComponent extends iBisBaseComponent {

    public declare edit: EditPartySpecializationService;
    constructor(public proxy: ProxyService) {
        super(proxy.partySpec, proxy.lg, proxy.iui, proxy.activatedRoute);
    }
}
