import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class EditMaintenanceService extends iBisSecureEditService {

	public notifyData: any;
	public isNotifyVisible: boolean;

	// TODO: Remove DataService from constructor
	public data: CustomStore;
	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService, private ds: DataService) {
		super(auth, entity, config, lg);
		this.validation = 'maintenanceValidation';
		this.title = 'MAINTENANCE';

		this.notifyData = [];
		this.isNotifyVisible = false;
	}
	protected load(): void {
		this.data = this.getData();
	}
	createDefault() {
		return {};
	}
	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.data = this.getData();
			this.entity.getById('Maintenance', id).then((obj) => {
				if (obj) {
					resolve(obj);
				} else {
					reject();
				}
			}, error => this.lg.showError(error));
		});
	}
	onRemove(id: string): Promise<void> {
		return this.entity.remove('Maintenance', id);
	}
	onSaveEv(): Promise<any> {
		this.model.partyId = this.ds.authDetails.party.id;
		return this.entity.save('Maintenance', this.model.id, this.model);
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('Maintenance').eq('partyId', this.ds.authDetails.party.id ?? null).addOrderByDesc(['fromDate']);
		return this.entity.store(q, false, 'id');
	}
}
