import { EditPartyListService } from '../party-list/edit-party-list.service';
import { ProxyService } from '../../../../service/proxy.service';
import { EditPartyService } from './edit-party.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component } from '@angular/core';
import { EditWorkingHourService } from '../../../../common/working-hour/edit-working-hour.service';

@Component({
  selector: 'app-edit-party',
  templateUrl: './edit-party.component.html'
})
export class EditPartyComponent extends iBisBaseComponent {

  constructor(public proxy: ProxyService, public edit: EditPartyService, public partyList: EditPartyListService, public workingHours: EditWorkingHourService) {
    super(null, proxy.lg, proxy.iui, proxy.activatedRoute);

    this.partyList.onDataChanged.subscribe((data) => {
      if (data === 'party-selected') {
        this.edit.partyId = this.partyList.partySelected;
      }
    })
  }

  onCountyValueChanged = (ev: any) => {
    if (ev.event) {
      this.edit.countyId = ev.value;
    }
  };
}
