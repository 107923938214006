import notify from 'devextreme/ui/notify';
import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EditSendSmsService } from './send-sms/send-sms.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-sms-queue',
	templateUrl: './sms-queue.component.html'
})
export class SmsQueueComponent extends iBisViewComponent {

	@ViewChild('gridSQ') declare grid: DxDataGridComponent;
	public declare edit: EditSendSmsService;

	private smsData: { id: string | null, value: 'ON' | 'OFF' };
	constructor(public proxy: ProxyService) {
		super(proxy.sendSms, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = '[MDS]Sms';
	}

	override toolbarPreparing(event: any) {
		super.toolbarPreparing(event, false);
		event.toolbarOptions.items.unshift({
			widget: 'dxButton',
			options: {
				text: 'Trimite SMS', 
				icon: 'message',
				onClick: (e: any) => {
					this.edit.showPopup(null);
				}
			},
			location: 'after'
		});

		event.toolbarOptions.items.push({
			template: 'smsText',
			location: 'before'
		});

		event.toolbarOptions.items.push({
			widget: 'dxSwitch',
			options: {
				width: '100',
				switchedOffText: 'Dezactivată', 
				switchedOnText: 'Activată', 
				onValueChanged: (e: any) => {
					if (e.event) {
						this.edit.addEditConfig(this.smsData.id, e.value ? 'ON' : 'OFF').then((res: any) => {
							this.smsData.id = res.id || res.key;
							this.smsData.value = e.value ? 'ON' : 'OFF';
							notify('Trimitere SMS automată a fost ' + (e.value ? 'activată' : 'dezactivată') +
								' cu succes.', 'success', 3000);
						}, error => this.proxy.lg.showError(error));
					}
				},
				onInitialized: (e: any) => {
					this.edit.getConfig().then((res: any) => {
						if (res) {
							this.smsData = res;
							e.component.option('value', res.value === 'ON');
						}
					}, err => this.proxy.lg.showError(err));
				}
			},
			location: 'before'
		});
	}
}
