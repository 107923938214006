import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig, iBisValidationService } from '@dohu/ibis-common';

@Injectable()

export class EditPatientService extends iBisSecureEditService {

	public data: CustomStore;

	private ft: boolean;

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService, private vatValid: iBisValidationService) {
		super(auth, entity, config, lg);
		this.title = 'Pacient';
		this.validation = 'patientValidation';
		this.ft = false;
	}

	protected load(): void {
		this.data = this.getData();
	}

	public set filter(filter: boolean) {
		this.ft = filter;
		this.data = this.getData();
	}

	public get filter() {
		return this.ft;
	}

	getById(id: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('Patient', id).then((res: any) => {
				if (res) {
					resolve(res);
				} else {
					reject();
				}
			}, error => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Patient', id);
	}

	onSaveEv(): Promise<any> {
		this.model.firstName = this.model.firstName.trim();
		this.model.lastName = this.model.lastName.trim();
		return this.entity.save('Patient', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	createDefault() {
		return { isActiv: true };
	}

	public checkCNP = (options: any) => {
		let m: any;
		if (options.value && options.value.length !== 0) {
			return this.vatValid.isCPNValid(options.value);
		}
		return true;
	}
	
	public reloadData(): void {
		this.data = this.getData();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('Patient').addOrderBy(['lastName']);
		if (this.ft) {
			const finishedApp = new EntityQuery('Appointment').eq('statusId', 5);
			const pwConsultations = new EntityQuery('Patient').addOrderBy(['firstName', 'lastName']);
			pwConsultations.link('id', 'patientId', finishedApp);
			pwConsultations.distinct = true;
			q.except(pwConsultations);
		}
		return this.entity.store(q, false, 'id');
	}
}
