import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { SendScheduleService } from './send-schedule.service';
import { ProxyService } from '../../../../service/proxy.service';

@Component({
	selector: 'app-send-schedule',
	templateUrl: './send-schedule.component.html'
})
export class SendScheduleComponent extends iBisBaseComponent {

	public declare edit: SendScheduleService;
	constructor(public proxy: ProxyService) {
		super(proxy.sendSchedule, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
