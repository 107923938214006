import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { EditDoctorService } from './edit-doctor.service';

@Component({
	selector: 'app-doctor',
	templateUrl: './doctor.component.html'
})
export class DoctorComponent extends iBisViewComponent {

	@ViewChild('doctorGrid') declare grid: DxDataGridComponent;
	
	constructor(public proxy: ProxyService, public edit: EditDoctorService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = '[MDS]Doctor';
	}

	public onRowExpanding(ev: any) {
		ev.component.collapseAll(-1);
	}
}
