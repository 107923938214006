import { confirm } from 'devextreme/ui/dialog';
import { iBisViewComponent } from '@dohu/ibis-common';
import { Component } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	templateUrl: 'user.component.html'
})
export class UserComponent extends iBisViewComponent {

	public customButtons; 
	constructor(public proxy: ProxyService) {
		super(proxy.user, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'u_id';
		this.key = '[MDS]Users';

		this.generateCustomButtons();
	}

	checkIfDoctor = (cell: any): boolean => {
		if (cell.row.data.roles.indexOf('doctor') > -1) {
			return true;
		}
		return false;
	}

	openAddParaf = (cell: any) => {
		confirm('Atentie imaginea ar trebui sa fie de marimea 375x335px cu fundal transparent. Continuati ?', 'Atentie').then((val) => {
			if (val) {
				this.proxy.file.upload(cell.row.data.u_id);
			}
		});
	}
	
	generateCustomButtons(): void {
		this.customButtons = [
			{hint: this.L('EDIT'), icon:'edit', onClick: this.onEditClick, visible: true},
			{hint: this.L('ADD_PARAF'), icon:'doc', onClick: this.openAddParaf, visible: this.checkIfDoctor},
		
		];
	}
}
