// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.cons-file-preview {
    width: calc(80% - 40px);
    height: 100%;
    display: flex;
}

.action-buttons {
    display: block;
    position: absolute;
    right: 53px;
    bottom: 10px;
    width: 30px;
}

.action-buttons dx-button{
  margin: 5px 0;
}
.patient-container {
    border-bottom: 1px solid rgba(221, 221, 221, 1);
  }

  .patient-details span {
    display: block;
    margin: 2px 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/file-preview/file-preview.component.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;AACf;;AAEA;EACE,aAAa;AACf;AACA;IACI,+CAA+C;EACjD;;EAEA;IACE,cAAc;IACd,aAAa;AACjB","sourcesContent":["\n.cons-file-preview {\n    width: calc(80% - 40px);\n    height: 100%;\n    display: flex;\n}\n\n.action-buttons {\n    display: block;\n    position: absolute;\n    right: 53px;\n    bottom: 10px;\n    width: 30px;\n}\n\n.action-buttons dx-button{\n  margin: 5px 0;\n}\n.patient-container {\n    border-bottom: 1px solid rgba(221, 221, 221, 1);\n  }\n\n  .patient-details span {\n    display: block;\n    margin: 2px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
