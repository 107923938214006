import { Component } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EditAppointmentService } from './edit-appointment.service';

@Component({
	selector: 'app-appointment',
	templateUrl: './appointment.component.html'
})
export class AppointmentComponent extends iBisViewComponent {

	public declare edit: EditAppointmentService;

	constructor(public proxy: ProxyService) {
		super(proxy.appointment, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
