import * as Bowser from 'bowser';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as labelNames from '../assets/label.json';
import { ProxyService } from './service/proxy.service';
import { NavigationEnd } from '@angular/router';
import { locale, loadMessages } from 'devextreme/localization';
import * as devExtremeMessages from '../assets/devExtreme.json';
import config from 'devextreme/core/config'; 
import { licenseKey } from '../devextreme-license'; 

declare var require: any;
config({ licenseKey }); 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	public menuOpened = false;
  version: { lastCommitNumber: number; branch: string; shortSHA: string };

  constructor(public http: HttpClient, public proxy: ProxyService) {
    this.proxy.router.events.subscribe((changes: any) => {
      if (changes instanceof NavigationEnd) {
        if (changes.url === '/login') {
          sessionStorage.removeItem('currentUserMDS');
          sessionStorage.removeItem('currentCompanyMDS');
        } else {
          this.proxy.ds.isLoginModalVisible = false;
        }
      }
    });
  }

  ngOnInit() {
    this.version = require('../git-version.json');
    locale('ro');
    loadMessages(<any>labelNames);
    loadMessages(<any>devExtremeMessages);
    const ui = this.proxy.iui.config.ui;
    if (ui.useNativ !== null && ui.useNativ !== undefined) {
      ui.isWindows = !ui.useNativ;
    } else {
      if (window.navigator.userAgent && Bowser) {
        const bowser = Bowser.getParser(window.navigator.userAgent);
        const os = Bowser ? bowser.getOS() || {} : {};
        if (os && os.name) {
          ui.isWindows = os.name === 'Windows';
        } else {
          ui.isWindows = false;
        }
      } else {
        ui.isWindows = false;
      }
    }
  }

  checkLogin() {
    return this.proxy.ds.isLoginModalVisible && this.proxy.router.url !== '/login';
  }
}

