import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { iBisAuthModule } from '@dohu/ibis-auth';
import { iBisCommonModule } from '@dohu/ibis-common';
import { AuthDetailsService } from './auth-details/auth-details.service';
import { AuthDetailsComponent } from './auth-details/auth-details.component';
import { PatientHistoryItemComponent } from './patient-history-item/patient-history-item.component';

import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { EditWorkingHourComponent } from './working-hour/edit-working-hour.component';
import { DxScrollViewModule } from 'devextreme-angular';
import { WorkingHourComponent } from './working-hour/working-hour.component';
@NgModule({
	declarations: [
		AuthDetailsComponent,
		PatientHistoryItemComponent,
		FilePreviewComponent,
		FileUploadComponent,
		EditWorkingHourComponent,
		WorkingHourComponent
	],
	imports: [
		BrowserModule,
		iBisAuthModule,
		DxFormModule,
		DxDataGridModule,
		DxTabPanelModule,
		DxListModule,
		DxButtonModule,
		DxFileUploaderModule,
		DxValidatorModule,
		DxScrollViewModule,
		iBisCommonModule
	],
	exports: [
		AuthDetailsComponent,
		PatientHistoryItemComponent,
		FilePreviewComponent,
		FileUploadComponent,
		EditWorkingHourComponent,
		WorkingHourComponent
	],
	providers: [AuthDetailsService]
})
export class CommonAppModule { }
