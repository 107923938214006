import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EditPartyListService extends iBisSecureEditService {

    public data: { id: string, text: string }[] = [];
    private dxList: any;
    private psId: string;

    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
        super(auth, entity, config, lg);
    }

    protected load(): void {
        this.getData();
    }

    public get partySelected(): string {
        return this.psId;
    }

    public set partySelected(id: string) {
        this.psId = id;
        this.onDataChanged.next('party-selected');
    }

    public set listInstance(instance: any) {
        if (this.dxList) {
            return;
        }
        this.dxList = instance;
        this.dxList.option('selectedItems', []);
    }

    createDefault() {
        return {};
    }
    getById(id: string): Promise<any> {
        throw new Error('Method not implemented.');
    }
    onRemove(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    onSaveEv(): Promise<any> {
        throw new Error('Method not implemented.');
    }
    reset(): void {
        this.model = this.createDefault();
    }

    public refresh(newPartyInserted = false, partyUpdated = false): void {
        this.getData(newPartyInserted, partyUpdated);
    }

    private getData(newPartyInserted = false, partyUpdated = false): void {
        const pu = new EntityQuery('PartyUser').eq('userId', this.auth.user.id).addOrderBy(['orderIndex']);
        const q = new EntityQuery('Party').addOrderBy(['name']).link('id', 'partyId', pu);
        q.distinct = true;
        this.entity.store(q, false, 'id').load().then((data: any) => {
            let aux = this.data;
            this.data = [{ id: '0', text: '+ Adaugă' }];
            data.forEach(element => {
                this.data.push({ id: element.id, text: element.name });
            });
            if (newPartyInserted) {
                var newParty = this.data.filter((resultA) => {
                    return !aux.find((resultB) => {
                        return resultA.id === resultB.id;
                    })
                });
                this.dxList.option('selectedItems', [this.data[this.data.findIndex(d => d.id === newParty[0].id)]])
            } else if (partyUpdated) {
                this.dxList.option('selectedItems', [this.data[this.data.findIndex(d => d.id === this.psId)]])
            } else {
                this.dxList.option('selectedItems', [this.data[this.data.length > 1 ? 1 : 0]]);
            }
        });
    }

}
