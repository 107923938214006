// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.cancel-btn {
    margin-left: 10px;
}

.register-header{
    background-color: #253648;
    color: #ffffff;
    border-bottom: 10px double white;
}
.register-header h1{
    text-align: center;
    margin:0;
    padding: 10px 0 0;
}

.register-body {
    width: 400px;
    margin: 50px auto 150px;
}

.register-btn {
    background: #faffff;
    color: #0e8c2d;
    border-radius: 10px;
    width: 100%;
}

.reset-btn {
    background: #faffff;
    color: #0e8c2d;
    border-radius: 10px;
    font-size: 14px;
}

.register-footer{
    position: fixed;
    width: 100%;
    bottom: 0;
    text-align: center;
    height: 100px;
    background-color: #253648;
    color: #ffffff;
    border-top: 10px double white;
}`, "",{"version":3,"sources":["webpack://./src/app/view/account/register/register.component.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gCAAgC;AACpC;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,6BAA6B;AACjC","sourcesContent":["\n.cancel-btn {\n    margin-left: 10px;\n}\n\n.register-header{\n    background-color: #253648;\n    color: #ffffff;\n    border-bottom: 10px double white;\n}\n.register-header h1{\n    text-align: center;\n    margin:0;\n    padding: 10px 0 0;\n}\n\n.register-body {\n    width: 400px;\n    margin: 50px auto 150px;\n}\n\n.register-btn {\n    background: #faffff;\n    color: #0e8c2d;\n    border-radius: 10px;\n    width: 100%;\n}\n\n.reset-btn {\n    background: #faffff;\n    color: #0e8c2d;\n    border-radius: 10px;\n    font-size: 14px;\n}\n\n.register-footer{\n    position: fixed;\n    width: 100%;\n    bottom: 0;\n    text-align: center;\n    height: 100px;\n    background-color: #253648;\n    color: #ffffff;\n    border-top: 10px double white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
