import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EditGeographicAreaService } from './edit-geographic-area.service';

@Component({
	selector: 'app-edit-geographic-area',
	templateUrl: './edit-geographic-area.component.html'
})
export class EditGeographicAreaComponent extends iBisBaseComponent {
	public declare edit: EditGeographicAreaService;

	private cboParent: any;
	constructor(public proxy: ProxyService) {
		super(proxy.geographicArea, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onTypeValueChanged = (event: any) => {
		if (event.event && this.cboParent) {
			switch (event.value) {
				case 1:
					this.cboParent.option('dataSource', this.proxy.geographicArea.county);
					break;
				case 2:
					this.cboParent.option('dataSource', this.proxy.geographicArea.zone);
					break;
				case 3:
					this.cboParent.option('dataSource', null);
					break;
			}
			this.edit.model.parentId = null;
		}
	}

	onParentInitialized = (ev: any) => {
		this.cboParent = ev.component;
	};
}
