import { iBisLanguageService, iBisServerConfig, iBisValidationService } from '@dohu/ibis-common';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';

@Injectable({
    providedIn: 'root'
})
export class EditCompanyService extends iBisSecureEditService {

    public readOnly: boolean = true;

    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService, private vatValid: iBisValidationService) {
        super(auth, entity, config, lg);
        this.validation = 'companyValidation';
    }

    protected load(): void {
        this.initSocietyData();
    }
    createDefault() {
        return {};
    }
    getById(id: string): Promise<any> {
        throw new Error('Method not implemented.');
    }
    onRemove(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    onSaveEv(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.saveCompanyDetails().then(() => {
                resolve('OK');
            }, error => {
                reject(error);
            });
        });
    }
    reset(): void {
        this.model = this.createDefault();
    }

    public cancelCompanyDetails() {
        this.readOnly = true;
        this.initSocietyData();
    }

	public checkCUI = (options: any) => {
		if (options.value && options.value.length !== 0) {
			return this.vatValid.isVATValid(options.value);
		}
		return true;
	}

    private initSocietyData() {
        this.entity.load(new EntityQuery('RegisterRequest').eq('deployId', this.config.deployId), this.config.saasServer).then((res) => {
            if (res && res.length) {
                const currentSociety = res.find((x: any) => x._Companie_ === this.auth.companyName);
                Object.assign(this.model, currentSociety);
            }
        });

        // this.entity.single(new EntityQuery('RegisterRequest').linkEq('id', 'requestId', 'Application', 'id', this.auth.companyId), this.config.saasServer).then((res) => {
        //     Object.assign(this.model, res);
        // });
    }

    private saveCompanyDetails() {
        return this.getEnumValueAttr(this.model.typeId).then((en: any) => {
            const enumValue = en;
            const attrFields = this.getRegisterAttrFields(this.model);
            const attrKeys = Object.keys(attrFields);
            return this.getRegisterModelAttr(this.model.id).then((rm: any) => {
                const requestArray = [];
                for (let i = 0; i < attrKeys.length; i++) {
                    let item: any = {};
                    if (rm && rm.length) {
                        // de facut update
                        item = rm.find((a: any) => {
                            return enumValue.find((b: any) => {
                                return a.attrId === b.id && b.attrName === attrKeys[i];
                            });
                        });
                        if (item && item.attrValue !== attrFields[attrKeys[i]]) {
                            item.attrValue = attrFields[attrKeys[i]];
                            requestArray.push(item);
                        }
                    }
                    if (!item && attrFields[attrKeys[i]]) {
                        const enumItem = enumValue.find((x: any) => x.attrName === attrKeys[i]);
                        const obj = {
                            attrValue: attrFields[attrKeys[i]],
                            attrId: enumItem.id,
                            parentId: this.model.id,
                        };
                        requestArray.push(obj);
                    }
                }
                if (requestArray.length) {
                    for (let j = 0; j < requestArray.length; j++) {
                        this.entity.save('RegisterRequest', requestArray[j].id, requestArray[j], this.config.saasServer).then(() => {
                            if (requestArray.length === j + 1) {
                                notify('Datele societății au fost modificate cu succes.', 'success', 3000);
                                this.readOnly = !this.readOnly;
                            }
                        }, error => {
                            this.lg.showError(error);
                            this.readOnly = !this.readOnly;
                        });
                    }
                } else {
                    this.readOnly = !this.readOnly;
                }
            }, error => {
                this.lg.showError(error);
                this.readOnly = !this.readOnly;
            });
        }, error => {
            this.lg.showError(error);
            this.readOnly = !this.readOnly;
        });
    }

    private getEnumValueAttr(parentId: string) {
        const ev = new EntityQuery('EnumValue').eq('name', 'MDS');
        const evAttr = new EntityQuery('EnumValueAttr').eq('parentId', parentId).link('parentId', 'id', ev);
        return this.entity.store(evAttr, false, 'id', this.config.saasServer).load();
    }

    private getRegisterAttrFields(model: any) {
        const keys = Object.keys(model);
        const newModel = {};
        for (let i = 0; i < keys.length; i++) {
            if (keys[i].indexOf('_') > -1) {
                const newKey = keys[i].substring(1, keys[i].length - 1);
                newModel[newKey] = model[keys[i]];
            }
        }
        return newModel;
    }

    private getRegisterModelAttr(parentId: string) {
        const q = new EntityQuery('RegisterRequestAttr').eq('parentId', parentId);
        return this.entity.store(q, false, 'id', this.config.saasServer).load();
    }
}
