import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { environment } from '../../../../environments/environment';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {

	user: any;
	passwordComparison;
	forgotPassword: boolean;
	resetEmail: string;
	registerData: any = {};
	@ViewChild('emailValidation') emailValidation: DxValidationGroupComponent;

	constructor(public proxy: ProxyService) { }

	ngOnInit() {
		this.proxy.activatedRoute.queryParams.subscribe(params => {
			this.forgotPassword = params['forgotPassword'] === 'true';
		});

		this.passwordComparison = () => {
			return this.registerData.correctPassword;
		};
		this.registerData = { isConfirm: false };
	}

	onRegister(e: any) {
		const validation = e.validationGroup.validate();
		if (!validation.isValid) {
			notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
			return;
		}

		const fields = ['_Companie_', '_CIF_', '_Telefon_'];
		const values = new Array();
		for (let i = 0; i < fields.length; i++) {
			values.push(this.registerData[fields[i]]);
		}
		const obj = {
			registerType: 'MDS',
			fullName: this.registerData.fullName,
			emailAddress: this.registerData.emailAddress,
			deployId: environment.deployId,
			fields: fields,
			values: values
		};

		const customDialog = custom({
			title: 'Alertă!',
			message: 'În câteva momente vei primi un e-mail de confirmare cu toate detaliile necesare. Îți mulțumim.',
			buttons: [{
				text: 'OK',
				onClick: () => {
					this.proxy.router.navigateByUrl('/login');
					this.registerData = { isConfirm: false };
				}
			}],
			showTitle: true
		});
		this.proxy.auth.register(obj).then(() => {
			customDialog.show();
		}, err => this.proxy.lg.showError(err));
	}

	onBack() {
		this.proxy.router.navigateByUrl('/login');
	}

	onResetPassword(e: any) {
		const validation = e.validationGroup.validate();
		if (!validation.isValid) {
			return;
		}
		this.proxy.auth.forgotPassword(this.resetEmail).then((result) => {
			// raspunsul vine prea devreme
			setTimeout(() => {
				if (result === 'OK') {
					notify('În câteva momente vei primi un e-mail de confirmare cu toate detaliile necesare. Îți mulțumim.',
						'success', 10000);
					this.emailValidation.instance.reset();
					setTimeout(() => {
						this.proxy.router.navigateByUrl('/login');
					}, 3000);
				} else {
					notify('Adresa de e-mail este invalidă', 'error', 10000);
				}
			}, 500);
		}, err => this.proxy.lg.showError(err));
	}
}

