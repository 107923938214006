// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-container {
  display: flex;
  position: relative;
  height: 250px;
  width: 100%;
}
.user-container .user-content {
  width: calc(100% - 400px);
  border: 1px solid rgb(221, 221, 221);
}

.list-item {
  border-bottom: 1px solid rgb(221, 221, 221);
}

.drop-here-text {
  text-align: center;
  vertical-align: middle;
  line-height: 250px;
}`, "",{"version":3,"sources":["webpack://./src/app/view/admin/company-details/edit-party-user-role/edit-party-user-role.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;AACJ;AACI;EACI,yBAAA;EACA,oCAAA;AACR;;AAGA;EACI,2CAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,sBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".user-container {\n    display: flex;\n    position: relative;\n    height: 250px;\n    width: 100%;\n\n    .user-content {\n        width: calc(100% - 400px);\n        border: 1px solid rgba(221, 221, 221, 1);\n    }\n}\n\n.list-item {\n    border-bottom: 1px solid rgba(221, 221, 221, 1);\n}\n\n.drop-here-text {\n    text-align: center;\n    vertical-align: middle;\n    line-height: 250px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
