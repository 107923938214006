import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { iBisLanguageService } from '@dohu/ibis-common';
import { iBisAuthService } from '@dohu/ibis-auth';
declare var $: any;

@Injectable()
export class SignalRService {

    public connection: any;
    public proxy: any;
    public listenerSubject: Subject<any> = new Subject<any>();

    constructor(public lg: iBisLanguageService, public auth: iBisAuthService) { }

    public initSignalR(token: any) {
        // $(() => {
        //     this.connection = $.hubConnection(environment.signalR);
        //     this.connection.qs = { Bearer: token, appId: this.auth.companyId };
        //     // this.connection.accessToken = token;
        //     this.proxy = this.connection.createHubProxy('SAASHub');
        //     this.proxy.on('generatedUserId', (userId: any) => {
        //         // With the userId set the chat will be rendered
        //         this.listenerSubject.next({ listenerType: 'generatedUserId', listenerRes: userId });
        //     }, (error: any) => this.lg.showError(error));

        //     this.proxy.on('messageReceived', (participant: any, message: any) => {
        //         // Handle the received message to ng-chat
        //         this.listenerSubject.next({ listenerType: 'messageReceived', listenerRes: { participant: participant, message: message } });
        //     }, (error: any) => this.lg.showError(error));

        //     this.proxy.on('friendsListChanged', (participantsResponse: Array<ParticipantResponse>) => {
        //         // Handle the received response to ng-chat
        //         this.listenerSubject.next({ listenerType: 'friendsListChanged', listenerRes: participantsResponse });
        //     }, (error: any) => this.lg.showError(error));

        //     this.proxy.on('NotifyModel', (model: any) => {
        //         this.listenerSubject.next({ listenerType: 'NotifyModel', listenerRes: model });
        //     }, (error: any) => this.lg.showError(error));

        //     this.connection.start({ jsonp: true })
        //         .done(() => {
        //             this.proxy.invoke('RegisterModel', this.auth.companyId, 'Appointment').then(() => {
        //                 console.log('SignalR is live!');
        //             }, (error: any) => this.lg.showError(error));
        //         })
        //         .fail(() => { console.log('Could not connect'); });

        //     this.connection.disconnected(() => {
        //         if (this.auth.isAuth) {
        //             this.connection.start({ jsonp: true })
        //                 .done(() => {
        //                     this.proxy.invoke('RegisterModel', this.auth.companyId, 'Appointment').then(() => {
        //                         console.log('SignalR is live!');
        //                     }, (error: any) => this.lg.showError(error));
        //                 })
        //                 .fail(() => { console.log('Could not connect'); });
        //         }
        //     });
        // });
    }
}
