import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { EditSendSmsService } from './send-sms.service';
import { ProxyService } from '../../../../service/proxy.service';

@Component({
	selector: 'app-edit-send-sms',
	templateUrl: './send-sms.component.html'
})
export class EditSendSmsComponent extends iBisBaseComponent {

	public declare edit: EditSendSmsService;
	constructor(public proxy: ProxyService) {
		super(proxy.sendSms, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
