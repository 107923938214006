import { Injectable } from "@angular/core";
import { DataService } from "../../../service/data.service";
import { EntityQuery, iBisEntityService } from "@dohu/ibis-entity";
import { iBisFileService, iBisServerConfig } from "@dohu/ibis-common";
import { iBisAuthService, iBisSecureService } from "@dohu/ibis-auth";
import { environment } from "../../../../environments/environment";

@Injectable()
export class CalendarService extends iBisSecureService {
	doctors= [];
	doctorSelected = [];
	specSelected = [];

	data: any;

	public doctodWorkingHours: { [doctorId: string]: string[] };
	public clinicWorkingHours: { startDayHour: number, endDayHour: number } = { startDayHour: 8, endDayHour: 20 };

	private cv: string = 'day';
	private sd: Date;


	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, protected file: iBisFileService,
		public ds: DataService) {
		super(auth, entity, config);

		this.sd = new Date(new Date().setHours(0, 0, 0, 0));
		this.doctodWorkingHours = {};
	}

	protected load(): void {
		this.refresh();
		this.getDoctorsBySpec();
	}

	set currentView(cv: string) {
		this.cv = cv;
		this.refresh();
	}

	get currentView(): string {
		return this.cv;
	}

	set selectedDate(selectedDate: Date) {
		const d = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0, 0);
		this.sd = d;
		this.refresh();
	}

	get selectedDate(): Date {
		return this.sd;
	}

	get isDoctor() {
		return this.ds.auth.isAuth ? (this.ds.authDetails.role.name == 'doctor') : false;
	}

	get isAssistent(): boolean {
		return this.ds.auth.isAuth ? (this.ds.authDetails.role.name == 'asistent') : false;
	}

	get isAdmin(): boolean {
		return this.ds.auth.isAuth ? (this.ds.authDetails.role.name == 'admin') : false;
	}

	public refresh() {
		this.refreshClinicWorkingHours();
		this.refreshDoctorWorkingHours().then(() => { this.data = this.getData() });
	}

	private getData() {

		const q = new EntityQuery('AppointmentView').addOrderBy(['startDate']);

		switch (this.cv) {
			case 'day':
				const fday = new Date(this.sd.getFullYear(), this.sd.getMonth(), this.sd.getDate(), 0, 0, 0, 0);
				const tday = new Date(this.sd.getFullYear(), this.sd.getMonth(), this.sd.getDate() + 1, 0, 0, 0, 0);
				q.gte('startDate', this.entity.toDateTimeFilter(fday)).lte('startDate', this.entity.toDateTimeFilter(tday));
				break;
			case 'week':
				break;
			case 'month':
				const fmonth = new Date(this.sd.getFullYear(), this.sd.getMonth(), 1, 0, 0, 0, 0);
				const tmonth = new Date(this.sd.getFullYear(), this.sd.getMonth() + 1, 0, 0, 0, 0);
				q.gte('startDate', this.entity.toDateTimeFilter(fmonth)).lte('startDate', this.entity.toDateTimeFilter(tmonth));
				break;
			default:
				break;
		}
		// check if we can get the doctor role from authDetails
		if (this.auth.user.roles.includes('doctor')) {
			q.eq('ap.userId', this.auth.user.id);
		} else if (this.doctorSelected.length > 0) {
			q.in('ap.userId', this.doctorSelected.map(x => x.id));
		}

		return this.entity.store(q, false, 'ap_id');
	}

	formatArray(data: any[], field: string, field2: string) {
		data.forEach((element: any) => {
			element[field] = element[field2];
		});
		return data;
	}

	downloadAppointment(data: any) {
		const obj = { appointmentId: data.ap_id, userId: this.ds.auth.user.id, doctorId: data.ap_userId };
		return this.file.downloadByPost(environment.defaultUrlServer + 'DownloadAppointment', obj);
	}

	public getDoctorsBySpec() {
		this.doctorSelected = [];
		const doctorRoleIdQ = new EntityQuery('UserLoginRole').eq('name', 'doctor').link('groupId', 'id', new EntityQuery('EnumValue')
			.eq('name', 'MDS').linkEq('typeId', 'id', 'EnumType', 'name', 'ROLE_GROUP'));
		this.entity.single(doctorRoleIdQ, this.config.saasServer).then((doctorRole: any) => {
			const q = new EntityQuery('PartyUser').eq('roleId', doctorRole.id);
			if (this.specSelected.length > 0) {
				q.link('userId', 'userId', new EntityQuery('UserSpecialization').in('specializationId', this.specSelected.map(s => s.id)));
			}

			this.entity.load(q).then((clinicDoctors: any[]) => {
				this.entity.store(new EntityQuery('UserLogin').eq('isActiv', true)
					.in('id', clinicDoctors.map(cd => cd.userId)), false, 'id', this.config.saasServer).load().then((resultedDoctors: any[]) => {
						this.doctors = resultedDoctors;
					})
			});
		});
	}

	public refreshDoctorWorkingHours(): Promise<any> {
		return new Promise((resolve, reject) => {
			const date = this.entity.toDateTimeFilter(this.sd);
			let q = new EntityQuery('WorkCalendar').lte('fromDate', date).gte('thruDate', date);
			this.entity.store(q, false, 'id').load().then((result: any) => {
				this.doctodWorkingHours = {};
				result.forEach(element => {

					if (this.isDayIncluded(element.dayOfWeek, this.sd.getDay())) {
						const start = new Date(element.workStart).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
						const end = new Date(element.workEnd).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
						const timeRange = `${start} - ${end}`;

						if (!this.doctodWorkingHours[element.entityId]) {
							this.doctodWorkingHours[element.entityId] = [];
						}
						this.doctodWorkingHours[element.entityId].push(timeRange);
					}
				});
				resolve(true);
			}, (error) => {
				console.log(error);
			});
		});
	}

	private isDayIncluded(dayOfWeek: number, selectedDay: number): boolean {
		const dayBitValues = [64, 1, 2, 4, 8, 16, 32];
		const dayBitValue = dayBitValues[selectedDay];
		return (dayOfWeek & dayBitValue) === dayBitValue;
	}

	private refreshClinicWorkingHours() {
		if(this.ds.authDetails.party.id){
			this.getClinicWorkingHours();
		} else {
		// TODO: because authDetails.party.id isn't yet loaded we wait 500s
		// try to find a way to remove timeout
			setTimeout(() => {
				this.getClinicWorkingHours();
			}, 1000);
		}
	}

	private getClinicWorkingHours(){
		// TODO: 
		// 1. sometimes we get 2 requests here
		// 2. implement break on clinics
		const date = this.entity.toDateTimeFilter(this.sd);
		const q = new EntityQuery('WorkCalendar').eq('entityId', this.ds.authDetails.party.id).lte('fromDate', date).gte('thruDate', date);
		this.entity.load(q).then((result: any) => {
			if(result.length === 0){
				// default
				this.clinicWorkingHours.startDayHour = 8;
				this.clinicWorkingHours.endDayHour = 20;
			} else {
				const scheduleHours = result.filter(r => this.isDayIncluded(r.dayOfWeek, this.sd.getDay()));
				if(scheduleHours.length === 0){
					this.clinicWorkingHours.startDayHour = null;
					this.clinicWorkingHours.endDayHour = null;
				} else {
					this.clinicWorkingHours.startDayHour = new Date(scheduleHours[0].workStart).getHours();
					this.clinicWorkingHours.endDayHour = new Date(scheduleHours[0].workEnd).getHours();
				}
			}
		});
	}
}
