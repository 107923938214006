import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, EntityConditionGroup, iBisEntityService } from '@dohu/ibis-entity';

@Injectable()

export class PatientHistoryService extends iBisEditService {

	data: any;
	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.validation = null;
		this.title = 'PATIENT_LIST_HISTORY';
	}

	getById(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.data = new DataSource({
				store: this.getFinishedAppointmentPatients(data.map(doctorSelected => doctorSelected.id)),
				paginate: true,
				pageSize: 30
			});
			resolve({});
		});
	}

	onRemove(id: string): Promise<void> {
		return new Promise((resolve) => { });
	}

	onSaveEv(): Promise<any> {
		return new Promise((resolve) => { });
	}

	reset(): void {
		this.model = {};
	}

	createDefault() {
		this.reset();
	}

	popupInit(ev: any) {
		super.popupInit(ev);
		ev.component.beginUpdate();
		ev.component.option('toolbarItems', []);
		ev.component.endUpdate();
	}

	getFinishedAppointmentPatients(ids: string[]) {
		const finishedApp = new EntityQuery('Appointment');
		if (ids.length > 1) {
			finishedApp.in('userId', ids).eq('statusId', 5);
		} else if(ids.length == 1){ // TODO: We should check if doctor after implementing authDetails
			finishedApp.eq('userId', ids[0]).in('statusId', [3,4,5]);
		}

		
		const q = new EntityQuery('PatientView').addOrderBy(['fullName']);
		q.link('p.id', 'patientId', finishedApp);
		q.distinct = true;

		return this.entity.store(q, false, 'p_id');
	}

}
