import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { iBisAuthModule } from '@dohu/ibis-auth';
import { iBisCommonModule } from '@dohu/ibis-common';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { BrowserModule } from '@angular/platform-browser';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxoSummaryModule } from 'devextreme-angular/ui/nested';
import { RegisterComponent } from './register/register.component';
import { TermsAndConditionsComponent } from './terms/terms.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';

@NgModule({
	declarations: [
		RegisterComponent,
		UserProfileComponent,
		TermsAndConditionsComponent
	],
	imports: [
		CommonModule,
		DxFormModule,
		BrowserModule,
		iBisAuthModule,
		DxButtonModule,
		DxCheckBoxModule,
		DxoSummaryModule,
		iBisCommonModule,
	],
	exports: [
		RegisterComponent,
		UserProfileComponent,
		TermsAndConditionsComponent
	]
})
export class AccountModule { }
