import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { SingleCardComponent } from './single-card/single-card.component';

@NgModule({
    declarations: [
        SingleCardComponent
    ],
    imports: [
        BrowserModule,
        DxPopupModule,
        DxButtonModule,
        DxDataGridModule,
        DxScrollViewModule
    ],
    exports: [
        SingleCardComponent
    ]
})
export class FrameworkModule { }
