import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import { Injectable } from '@angular/core';

@Injectable()

export class EditPartySpecializationService extends iBisSecureEditService {


	public data: CustomStore;
	public clinicSpecs: any = [];

	private initialClinicSpecs: any = [];
	private partyId: string;

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
		super(auth, entity, config, lg);
		this.validation = null;
		this.title = this.L('USER_SPECIALIZATION');
	}

	public createDefault(): void {
		this.reset();
	}

	public getById(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.partyId = data.partyId;
			let specs = [];
			Object.assign(specs, data.partySpecializations);
			specs = specs.map(s => s.id);
			specs.shift();

			this.initialClinicSpecs = specs
			this.clinicSpecs = specs;
			resolve(true);
		});
	}

	public onRemove(id: string): Promise<void> {
		return new Promise((resolve) => { });
	}

	public onSaveEv(): Promise<any> {
		return new Promise((resolve) => {
			const toDelete = this.differencesInArrays(this.initialClinicSpecs, this.clinicSpecs);
			const toInsert = this.differencesInArrays(this.clinicSpecs, this.initialClinicSpecs);
			const promises = [];
			if (toDelete.length > 0) {
				promises.push(this.deleteSpecialization(toDelete));
			}
			if (toInsert.length > 0) {
				promises.push(this.addPartySpecialization(toInsert))
			}
			Promise.all(promises).then(() => {
				resolve('OK');
			}, error => {
				this.lg.showError(error);
				resolve('ERROR');
			});
		});
	}

	public reset(): void {
		this.model = {};
		this.clinicSpecs = [];
		this.initialClinicSpecs = [];
	}

	protected load(): void {
		this.data = this.getData();
	}

	private deleteSpecialization(toDelete: any) {
		return new Promise((resolve: any) => {
			const usQuery = new EntityQuery('UserSpecialization').eq('partyId', this.partyId).in('specializationId', toDelete);
			const psQuery = new EntityQuery('PartySpecialization').eq('partyId', this.partyId).in('specializationId', toDelete);

			Promise.all([this.entity.load(usQuery), this.entity.load(psQuery)]).then((values: any) => {
				let removePromisses = [];
				values[0].forEach(ups => {
					removePromisses.push(this.entity.remove('UserSpecialization', ups.id));
				});
				Promise.all(removePromisses).then(() => {
					removePromisses = [];
					values[1].forEach(ps => {
						removePromisses.push(this.entity.remove('PartySpecialization', ps.id));
					});

					Promise.all(removePromisses).then(() => {
						resolve('OK');
					}, error => {
						this.lg.showError(error);
						resolve('ERROR');
					});
				}, error => {
					this.lg.showError(error);
					resolve('ERROR');
				});
			}, error => {
				this.lg.showError(error);
				resolve('ERROR');
			});
		});
	}

	private addPartySpecialization(toInsert: any) {
		return new Promise((resolve: any) => {
			const insertPromisses = [];

			toInsert.forEach(specId => {
				insertPromisses.push(this.entity.insert('PartySpecialization', { partyId: this.partyId, specializationId: specId }));
			});

			Promise.all(insertPromisses).then(() => {
				resolve('OK');
			}, error => {
				this.lg.showError(error);
				resolve('ERROR');
			});
		});
	}

	private differencesInArrays(arr1: any, arr2: any) {
		const difference = arr1.filter((x: any) => !arr2.includes(x));
		return difference;
	}

	private getData(): CustomStore {
		return this.entity.store(new EntityQuery('Specialization').eq('isActiv', true).addOrderBy(['description']));
	}
}
