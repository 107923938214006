import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EditPartyUserRoleService extends iBisSecureEditService {

    public roles: any[];
    public clinicUsers: any[];
    public users: any[];

    private urId: string;
    private pId: string;
    private isDr: boolean;

    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
        super(auth, entity, config, lg);
    }

    public set userRoleSelected(id: string) {
        this.urId = id;
        this.getPartyUserById();
    }

    public set partyId(id: string) {
        this.pId = id;
        this.getPartyUserById();
    }

    public set isDoctorRole(isDoctorRole: boolean) {
        this.isDr = isDoctorRole;
    }

    protected load(): void {
        this.getRoles();
        this.getUsers();
    }

    createDefault() {
        return {};
    }
    getById(id: string): Promise<any> {
        throw new Error('Method not implemented.');
    }
    onRemove(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    onSaveEv(): Promise<any> {
        throw new Error('Method not implemented.');
    }
    reset(): void {
        this.model = this.createDefault();
    }

    public updateUserRoleList(id: string, remove: boolean) {
        this.entity.load(new EntityQuery('PartyUser').eq('partyId', this.pId).eq('roleId', this.urId).addOrderBy(['orderIndex'])).then((allUsers) => {
            if (remove) {
                this.removeUserRole(id, allUsers);
            } else {
                this.addUserRoleToParty(id, allUsers);
            }
        });
    }

    private removeUserRole(id: string, allUsers: any[]) {
        this.entity.update('PartyUser', id, { isActiv: false, inactiveFrom: new Date(), orderIndex: allUsers.length });
        allUsers = allUsers.filter((au) => au.id === id);
        for (let index = 0; index < allUsers.length; index++) {
            this.entity.update('PartyUser', allUsers[index].id, { orderIndex: index }).then(() => {
                this.getPartyUserById();
            });
        }
    }

    private addUserRoleToParty(id: string, allUsers: any[]) {
        const q = new EntityQuery('PartyUser').eq('partyId', this.pId).eq('roleId', this.urId).eq('userId', id);
        this.entity.single(q).then((result) => {
            let numberOfUsersActiv = allUsers.filter((u) => u.isActive === true).length;
            let inactiveUsers = allUsers.filter((u) => u.isActive === false);
            if (result) {
                result.isActiv = true;
                result.inactiveFrom = null;
                result.orderIndex = numberOfUsersActiv;
                this.entity.update('PartyUser', result.id, result).then(() => {
                    this.getPartyUserById();
                });
                inactiveUsers = inactiveUsers.filter((iu) => iu.id === result.id);

            } else {
                this.insertNewUserRole(id, numberOfUsersActiv);
            }
            //TODO refresh after updating the list
            inactiveUsers.forEach(iu => {
                this.entity.update('PartyUser', iu.id, { orderIndex: ++numberOfUsersActiv });
            });
        });
    }

    private insertNewUserRole(userId: string, orderIndex: number) {
        let obj: any = {};
        obj.userId = userId;
        obj.partyId = this.pId;
        obj.isActiv = true;
        obj.inactiveFrom = null;
        obj.roleId = this.urId;
        obj.orderIndex = orderIndex;
        this.entity.insert('PartyUser', obj).then(() => {
            this.getPartyUserById();
        });
        // if(this.isDr) {
        // TODO: Check if we need to insert the workingSchedule data for the doctor
        // }
    }

    private getRoles(): void {
        const q = new EntityQuery('UserLoginRole').addOrderBy(['name']);
        q.link('groupId', 'rolegroupid', new EntityQuery('ApplicationConfig')
            .linkEq('id', 'configId', 'Application', 'id', this.auth.companyId));
        this.entity.load(q, this.config.saasServer).then((result: any[]) => {
            if (result) {
                this.roles = result;
            }
        });
    }
    private getPartyUserById() {
        const q = new EntityQuery('PartyUser').eq('partyId', this.pId).eq('roleId', this.urId).eq('isActiv', true).addOrderBy(['orderIndex']);
        this.entity.load(q).then((result: any[]) => {
            this.clinicUsers = [];
            if (result) {
                result.forEach(pu => {
                    let user = this.users.find(u => u.id === pu.userId);
                    user.orderIndex = pu.orderIndex;
                    user.partyUserId = pu.id;
                    this.clinicUsers.push(user);
                });
            }
        });
    }

    private getUsers() {
        const q = new EntityQuery('UserLogin').eq('isActiv', true);
        q.link('id', 'userId', new EntityQuery('ApplicationRole').eq('appId', this.auth.companyId));
        q.distinct = true;
        this.entity.load(q, this.config.saasServer).then((result: any[]) => {
            if (result) {
                this.users = result;
            }
        })
    }
}
