import { ProxyService } from '../../../../service/proxy.service';
import { EditCompanyService } from './edit-company.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html'
})
export class EditCompanyComponent extends iBisBaseComponent {
  public declare edit: EditCompanyService;

  constructor(public proxy: ProxyService) {
    super(proxy.company, proxy.lg, proxy.iui, proxy.activatedRoute);
  }
}

