// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-list {
    display: flex;
}
.patient-list dx-list{
    width: 20%;
}
.patient-list > dx-tab-panel{
    width: 80%;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #ccc;
}
.details-panel{
    background-color: white;
}

.historyDetail {
    margin-top: 10px;
}


.patient-container {
    border-bottom: 1px solid rgba(221, 221, 221, 1);
}

.patient-details span {
    display: block;
    margin: 2px 0;
}

.history-item .dx-datagrid-rowsview.dx-datagrid-nowrap{
    white-space: normal !important;
}`, "",{"version":3,"sources":["webpack://./src/app/view/scheduler/calendar/patient-history/patient-history.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,iBAAiB;IACjB,2BAA2B;AAC/B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,+CAA+C;AACnD;;AAEA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".patient-list {\n    display: flex;\n}\n.patient-list dx-list{\n    width: 20%;\n}\n.patient-list > dx-tab-panel{\n    width: 80%;\n    padding-left: 10px;\n    margin-left: 10px;\n    border-left: 1px solid #ccc;\n}\n.details-panel{\n    background-color: white;\n}\n\n.historyDetail {\n    margin-top: 10px;\n}\n\n\n.patient-container {\n    border-bottom: 1px solid rgba(221, 221, 221, 1);\n}\n\n.patient-details span {\n    display: block;\n    margin: 2px 0;\n}\n\n.history-item .dx-datagrid-rowsview.dx-datagrid-nowrap{\n    white-space: normal !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
