import { iBisBaseComponent } from '@dohu/ibis-common';
import { EditDashboxService } from './edit-dashbox.service';
import { ProxyService } from '../../../service/proxy.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-dashbox',
	templateUrl: './dashbox.component.html',
	styleUrls: ['./dashbox.component.css']
})
export class DashboxComponent extends iBisBaseComponent implements OnInit {
	@Input() dateInput: any;
	currentHover: { hint: any; target: any; isPopVisible: boolean; };

	public dashBox: EditDashboxService;
	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	ngOnInit(): void {
		this.currentHover = { target: '#', hint: '', isPopVisible: false };
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.dateInput && changes.dateInput.currentValue) {
			this.dashBox.initDashIndex(new Date(changes.dateInput.currentValue));
		}
	}

	toggleDefault(item: any) {
		this.currentHover = { hint: item.hint, target: item.target, isPopVisible: !this.currentHover.isPopVisible };
	}
}
