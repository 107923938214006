import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EditDiagnosticService } from './edit-diagnostic.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-diagnostic',
	templateUrl: './diagnostic.component.html'
})
export class DiagnosticComponent extends iBisViewComponent {

	@ViewChild('diagnosticGrid') declare grid: DxDataGridComponent;
	public declare edit: EditDiagnosticService;

	constructor(public proxy: ProxyService) {
		super(proxy.diagnostic, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = '[MDS]Diagnostic';
	}
}
