import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EditPartyWorkingScheduleService extends iBisSecureEditService {

    public data: any[];

    private pId: string;
    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
        super(auth, entity, config, lg);
    }

    protected load(): void {
    }

    public set partyId(id: string) {
        this.pId = id;
        this.getData();
    }

    createDefault() {
        return {};
    }
    getById(id: string): Promise<any> {
        throw new Error('Method not implemented.');
    }
    onRemove(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    onSaveEv(): Promise<any> {
        return this.entity.save('WorkingSchedule', this.model.id, this.model);
    }
    reset(): void {
        this.model = this.createDefault();
    }

    private getData(): void {
        // TODO: move to the new tables
        // this.entity.load(new EntityQuery('WorkingSchedule').eq('partyId', this.pId).addOrderBy(['weekDay'])).then((result: any) => {
        //     if (result) {
        //         this.data = [{
        //             id1: result[0].id,
        //             startTime1: result[0].startTime,
        //             endTime1: result[0].endTime,
        //             id2: result[1].id,
        //             startTime2: result[1].startTime,
        //             endTime2: result[1].endTime,
        //             id3: result[2].id,
        //             startTime3: result[2].startTime,
        //             endTime3: result[2].endTime,
        //             id4: result[3].id,
        //             startTime4: result[3].startTime,
        //             endTime4: result[3].endTime,
        //             id5: result[4].id,
        //             startTime5: result[4].startTime,
        //             endTime5: result[4].endTime,
        //             id6: result[5].id,
        //             startTime6: result[5].startTime,
        //             endTime6: result[5].endTime,
        //             id0: result[6].id,
        //             startTime0: result[6].startTime,
        //             endTime0: result[6].endTime,
        //         }];
        //     } else {
        //         this.data = [];
        //     }
        // });
    }
}
