import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { iBisAuthService, iBisAuthModule, iBisEditUserService } from '@dohu/ibis-auth';
import { iBisCommonModule, iBisGridProperties, iBisUIService, iBisFileService, iBisLanguageService } from '@dohu/ibis-common';
import { AppComponent } from './app.component';
import * as gridLabel from '@dohu/ibis-common/int/ro/label.json';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { ScreenService } from './service/screen.service';
import { ProxyService } from './service/proxy.service';
import { DataService } from './service/data.service';
import { SignalRService } from './service/signalR.service';

import * as ui from '../assets/uiCustomize.json';
import * as display from '../assets/display.json';
import * as nav from '../assets/navigation.json';
import * as label from '../assets/label.json';
import * as labelDevEx from '../assets/devExtreme.json';
import * as labelExtra from '../assets/label.extra.json';

import { AdminModule } from './view/admin/admin.module';
import { DashboardModule } from './view/dashboard/dashboard.module';
import { FrameworkModule } from './framework/framework.module';
import { AccountModule } from './view/account/account.module';
import { SchedulerModule } from './view/scheduler/scheduler.module';

import { iBisWSModule } from '@dohu/ibis-ws';
import { iBisEntityModule } from '@dohu/ibis-entity';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';

import { environment } from '../environments/environment';
import { CommonAppModule } from './common/common.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { MessageComponent } from './view/message/message.component';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxDrawerModule, DxTreeViewModule } from 'devextreme-angular';
import { ReportModule } from './view/report/report.module';


@NgModule({ declarations: [
        AppComponent,
        MessageComponent,
    ],
    bootstrap: [AppComponent], 
    imports: [BrowserModule,
        FormsModule,
        AppRoutingModule,
        ReportModule,
        AdminModule,
        CommonModule,
        CommonAppModule,
        DashboardModule,
        FormsModule,
        FrameworkModule,
        AccountModule,
        SchedulerModule,
        DxDataGridModule,
        DxListModule,
        DxTextBoxModule,
        DxScrollViewModule,
        DxDrawerModule,
        DxTreeViewModule,
        iBisAuthModule,
        iBisWSModule.forRoot({
			server: environment.notifyServer,
			models: ['Appointment']
		}),
        iBisEntityModule.forRoot({ key: 'id', server: environment.defaultUrlServer }),
        iBisCommonModule.forRoot({
            display: display,
            logo: 'assets/img/popup_logo.png',
            lang: 'ro',
            label: [labelDevEx.default, gridLabel.default, labelExtra.default, label.default],
            grid: ui.default.grid as iBisGridProperties,
            ui: ui.default.ui,
        }, {
            saasServer: environment.saasServer,
            fileServer: environment.saasServer,
            authServer: environment.authServer,
            deployId: environment.deployId,
            useLocalStorage: true,
            skipLocation: false
        }),
        iBisNavigationModule.forRoot({
            navigation: nav.default, shortcut: nav.default.shortcut,
            ignorePath: ['/login', '/resetPassword', '/register', '/confirmInvite'], outer: true
        })],
        providers: [
        DataService,
        ScreenService,
        SignalRService,
        HttpClient,
        ProxyService,
        iBisAuthService,
        iBisUIService,
        iBisFileService,
        iBisEditUserService,
        iBisLanguageService,
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule { }
