import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EditProcedureService } from './edit-procedure.service';

@Component({
	selector: 'app-edit-procedure',
	templateUrl: './edit-procedure.component.html'
})
export class EditProcedureComponent extends iBisBaseComponent {
	public declare edit: EditProcedureService;
	constructor(public proxy: ProxyService) {
		super(proxy.procedure, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
