import { Injectable } from '@angular/core';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';

@Injectable({
    providedIn: 'root'
})
export class EditPartyUserSpecializationService extends iBisSecureEditService {

    public specs: any[];
    public partyDoctors: any[];
    public specDoctors: any[];

    private dxSpecList: any;
    private pId: string;
    private sId: string;

    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
        super(auth, entity, config, lg);
    }

    protected load(): void {
    }

    public get partyId(): string {
        return this.pId;
    }

    public set partyId(id: string) {
        this.pId = id;
        this.getDoctors();
        if (this.sId) {
            this.getSpecDoctors();
        }
    }

    public set specializationId(id: string) {
        this.sId = id;
        this.getSpecDoctors();
    }

    public refreshDoctorList(): void {
        this.getDoctors();
    }

    public refreshSpecList(): void {
        this.getSpecs();
    }

    public set specListInstance(instance: any) {
        if (this.dxSpecList) {
            return;
        }
        this.dxSpecList = instance;
        this.dxSpecList.option('selectedItems', []);
    }

    createDefault() {
        return {};
    }
    getById(id: string): Promise<any> {
        throw new Error('Method not implemented.');
    }
    onRemove(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    onSaveEv(): Promise<any> {
        throw new Error('Method not implemented.');
    }
    reset(): void {
        this.model = this.createDefault();
    }

    public removeDoctorSpecs(id: string) {
        this.entity.single(new EntityQuery('UserSpecialization').eq('userId', id).eq('specializationId', this.sId).eq('partyId', this.pId)).then((spec) => {
            this.entity.remove('UserSpecialization', spec.id).then(() => {
                this.getSpecDoctors();
            }, error => this.lg.showError(error));
        });
    }

    public addDoctorToSpecs(id: string) {
        this.entity.insert('UserSpecialization', { userId: id, specializationId: this.sId, partyId: this.pId }).then(() => {
            this.getSpecDoctors();
        }, error => this.lg.showError(error));
    }

    private getSpecs(): void {
        const q = new EntityQuery('Specialization').eq('isActiv', true)
            .linkEq('id', 'specializationId', 'PartySpecialization', 'partyId', this.pId).addOrderBy(['description']);
        this.entity.load(q).then((result: any[]) => {
            this.specs = [{ id: 0, description: ' +/- Editează lista' }];
            if (result && result.length > 0) {
                result.forEach(element => this.specs.push(element));
                this.dxSpecList.option('selectedItems', [this.specs[1]]);
            }
        });
    }

    private getDoctors(): void {
        const doctorRoleIdQ = new EntityQuery('UserLoginRole').eq('name', 'doctor').link('groupId', 'id', new EntityQuery('EnumValue')
            .eq('name', 'MDS').linkEq('typeId', 'id', 'EnumType', 'name', 'ROLE_GROUP'));
        this.entity.single(doctorRoleIdQ, this.config.saasServer).then((doctorRole: any) => {
            const q = new EntityQuery('PartyUser').eq('partyId', this.pId).eq('roleId', doctorRole.id);
            this.entity.load(q).then((clinicDoctors: any[]) => {
                this.entity.load(new EntityQuery('UserLogin').in('id', clinicDoctors.map(cd => cd.userId)), this.config.saasServer).then((doctors: any[]) => {
                    this.partyDoctors = doctors ?? [];
                    this.getSpecs();
                });
            });
        });
    }

    private getSpecDoctors(): void {
        const q = new EntityQuery('UserSpecialization').eq('partyId', this.pId).eq('specializationId', this.sId);
        this.entity.load(q).then((specUsers: any[]) => {
            this.specDoctors = this.partyDoctors.filter(pd => specUsers.find(su => pd.id === su.userId));
        });
    }
}
