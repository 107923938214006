import { EditPartyUserSpecializationService } from './edit-party-user-specialization.service';
import { EditPartyListService } from '../party-list/edit-party-list.service';
import { ProxyService } from '../../../../service/proxy.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { confirm } from 'devextreme/ui/dialog';
import { Component } from '@angular/core';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-edit-party-user-specialization',
    templateUrl: './edit-party-user-specialization.component.html',
    styleUrls: ['./edit-party-user-specialization.component.scss']
})

export class EditPartyUserSpecializationComponent extends iBisBaseComponent {

    constructor(public proxy: ProxyService, public edit: EditPartyUserSpecializationService, private partyList: EditPartyListService) {
        super(null, proxy.lg, proxy.iui, proxy.activatedRoute);

        this.partyList.onDataChanged.subscribe((data) => {
            if (data === 'party-selected') {
                this.edit.partyId = this.partyList.partySelected;
            }
        })
    }

    onSpecListInit(event: any): void {
        this.edit.specListInstance = event.component;
    }

    onSpecChanged(ev: any) {
        if (ev.addedItems[0].id === 0) {
            this.proxy.partySpec.showPopup({ partyId: this.edit.partyId, partySpecializations: this.edit.specs }, true).then((res: any) => {
                // TODO: When res === null => closed popup without any changes
                // if res === null then select last Specialization selected before 'Editează lista'
                if (res === 'OK') {
                    this.edit.refreshSpecList();
                }
            });
        } else {
            this.edit.specializationId = ev.addedItems[0].id;
        }
    }

    onDoctorDeleting(ev: any) {
        const promise = new Promise((resolve: any) => {
            confirm(this.L('CONFIRMATION_DOCTOR_DELETE'), this.L('ATTENTION')).then((val) => {
                if (!val) {
                    resolve(!val);
                } else {
                    this.edit.removeDoctorSpecs(ev.itemData.id);
                }
            });
        });
        ev.cancel = promise;
    }

    onDragStart = (e: any) => {
        e.itemData = e.fromData[e.fromIndex];
    }

    onDragDoctorEnd = (e: any) => {
        if (e.fromComponent === e.toComponent) {
            return;
        } else if (e.toData !== e.fromData || e.toData.length === 0) {
            const userExists = this.edit.specDoctors.find((x: any) => x.id === e.itemData.id);
            if (userExists) {
                notify('Doctorul este deja asociat specializării.', 'warning', 3000);
                return;
            }

            this.edit.addDoctorToSpecs(e.itemData.id);
        }
    }
}