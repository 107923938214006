import { Injectable } from '@angular/core';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({
    providedIn: 'root'
})
export class EditPartyOperatingRoomService extends iBisSecureEditService {

    public data: CustomStore;

    private pId: string;

    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
        super(auth, entity, config, lg);
    }

    protected load(): void {
        this.data = this.getData();
    }

    public get partyId(): string {
        return this.pId;
    }

    public set partyId(id: string) {
        this.pId = id;
        this.data = this.getData();
    }


    createDefault() {
        return {};
    }
    getById(id: string): Promise<any> {
        throw new Error('Method not implemented.');
    }
    onRemove(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    onSaveEv(): Promise<any> {
        throw new Error('Method not implemented.');
    }
    reset(): void {
        this.model = this.createDefault();
    }

    private getData(): CustomStore {
        var q = new EntityQuery('OperatingRoom').addOrderBy(['name']);
        if (this.pId) {
            q.eq('partyId', this.pId);
        }
        return this.entity.store(q);
    }
}
