
import { EditPartyDataService } from './edit-party-data.service';
import { ProxyService } from '../../../../service/proxy.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component } from '@angular/core';
import { EditPartyListService } from '../party-list/edit-party-list.service';


@Component({
    selector: 'app-party-data',
    templateUrl: './party-data.component.html'
})

export class PartyDataComponent extends iBisBaseComponent {

    constructor(public proxy: ProxyService, public edit: EditPartyDataService, public partyList: EditPartyListService) {
        super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
    }
}