// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-container {
  padding: 10px 10px 0;
  margin: 0 auto;
  background: white;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.actions {
  width: 250px;
}
.actions .dx-calendar {
  width: 250px;
  height: 250px;
}
.actions .marg-padding-7 {
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (max-width: 650px) {
  .calendar-container .row {
    height: 85vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .calendar-container .row .actions {
    order: 1;
    width: 95vw;
  }
  .calendar-container .row .actions dx-calendar {
    display: none;
  }
  .calendar-container .row .actions .options .selectList {
    display: inline-block !important;
    width: calc(50vw - 20px);
    height: calc(15vh - 10px);
    border: none;
  }
  .calendar-container .row .actions .options .selectList label {
    font-size: 14px;
    font-weight: 500;
  }
  .calendar-container .row .actions .selectList dx-list {
    height: 75px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/view/scheduler/calendar/calendar.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,cAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,eAAA;AACJ;;AAGA;EACI,YAAA;AAAJ;AAEI;EACI,YAAA;EACA,aAAA;AAAR;AAGI;EACI,kBAAA;EACA,mBAAA;EACA,gCAAA;AADR;;AAKA;EAEQ;IAEI,YAAA;IACA,aAAA;IACA,eAAA;IACA,sBAAA;EAJV;EAMU;IACI,QAAA;IACA,WAAA;EAJd;EAMc;IACI,aAAA;EAJlB;EAQkB;IACI,gCAAA;IACA,wBAAA;IACA,yBAAA;IACA,YAAA;EANtB;EAQsB;IACI,eAAA;IACA,gBAAA;EAN1B;EAWc;IACI,YAAA;EATlB;AACF","sourcesContent":[".calendar-container {\n    padding: 10px 10px 0;\n    margin: 0 auto;\n    background: white;\n}\n\n.row {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n\n.actions {\n    width: 250px;\n\n    .dx-calendar {\n        width: 250px;\n        height: 250px;\n    }\n\n    .marg-padding-7 {\n        margin-bottom: 7px;\n        padding-bottom: 7px;\n        border-bottom: 1px solid #ebebeb;\n    }\n}\n\n@media only screen and (max-width: 650px) {\n    .calendar-container {\n        .row {\n\n            height: 85vh;\n            display: flex;\n            flex-wrap: wrap;\n            flex-direction: column;\n\n            .actions {\n                order: 1;\n                width: 95vw;\n\n                dx-calendar {\n                    display: none;\n                }\n\n                .options {\n                    .selectList {\n                        display: inline-block !important;\n                        width: calc(50vw - 20px);\n                        height: calc(15vh - 10px);\n                        border: none;\n\n                        label {\n                            font-size: 14px;\n                            font-weight: 500;\n                        }\n                    }\n                }\n\n                .selectList dx-list {\n                    height: 75px;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
