import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { EditWorkingHourService } from './edit-working-hour.service';

@Component({
    selector: 'app-working-hour',
    templateUrl: './working-hour.component.html'
})
export class WorkingHourComponent extends iBisViewComponent implements OnInit {

    @Input() entityId: any;
    @ViewChild('WorkingHourGrid') declare grid: DxDataGridComponent;

    constructor(public proxy: ProxyService, public edit: EditWorkingHourService) {
        super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
    }

    ngOnInit(): void {
        this.edit.entityId = this.entityId;
    }

    public onGridInit(e: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.edit.getData());
        }
    }

    public toolbarPreparing(event: any) {
        super.toolbarPreparing(event, false);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            location: 'after',
            options: {
                icon: 'clock',
                hint: this.L('EDIT_WORKING_HOURS'),
                onClick: (ev: any) => {
                    this.edit.showPopup(null, true).then(() => {
                        event.component.option('dataSource', this.edit.getData());
                    })
                }
            }
        });
    }

    public showWorkingDays = (rowData: any): string => {
        return this.formatWorkingDays(rowData.dayOfWeek);
    }

    public formatWorkStart = (rowData: any): string =>{
        return this.formatTime(new Date(rowData.workStart));
    }

    public formatWorkEnd = (rowData: any): string =>{
        return this.formatTime(new Date(rowData.workEnd));
    }

	private formatTime(dateTime: Date): string {
		return dateTime === null ? '' :
		dateTime.getHours() + ':' + this.proxy.entity.toD2(dateTime.getMinutes());
	}

    private formatWorkingDays(workingDays: number){
        let displayValue = [];
        if((workingDays & 1) > 0) displayValue.push(this.L('MONDAY'));
        if((workingDays & 2) > 0) displayValue.push(this.L('THUESDAY'));
        if((workingDays & 4) > 0) displayValue.push(this.L('WEDNESDAY'));
        if((workingDays & 8) > 0) displayValue.push(this.L('THURSDAY'));
        if((workingDays & 16) > 0) displayValue.push(this.L('FRIDAY'));
        if((workingDays & 32) > 0) displayValue.push(this.L('SATURDAY'));
        if((workingDays & 64) > 0) displayValue.push(this.L('SUNDAY'));

        return displayValue.join(', ');
    }
}
