import { EditPartyListService } from '../party-list/edit-party-list.service';
import { EditPartyUserRoleService } from './edit-party-user-role.service';
import { ProxyService } from '../../../../service/proxy.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { confirm } from 'devextreme/ui/dialog';
import { Component } from '@angular/core';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-edit-party-user-role',
    templateUrl: './edit-party-user-role.component.html',
    styleUrls: ['./edit-party-user-role.component.scss']
})
export class EditPartyUserRoleComponent extends iBisBaseComponent {

    constructor(public proxy: ProxyService, public edit: EditPartyUserRoleService, private partyList: EditPartyListService) {
        super(null, proxy.lg, proxy.iui, proxy.activatedRoute);

        this.partyList.onDataChanged.subscribe((data) => {
            if (data === 'party-selected') {
                this.edit.partyId = this.partyList.partySelected;
            }
        })
    }

    onRoleChanged(ev: any) {
        if (ev.addedItems && ev.addedItems.length) {
            this.edit.userRoleSelected = ev.addedItems[0].id;
            this.edit.isDoctorRole = ev.addedItems[0].name === 'doctor';
        }
    }

    onPartyUserDeleting(ev: any) {
        const promise = new Promise((resolve: any) => {
            confirm(this.L('CONFIRMATION_ROLE_DELETE'), this.L('ATTENTION')).then((val) => {
                if (!val) {
                    resolve(!val);
                } else {
                    this.edit.updateUserRoleList(ev.itemData.partyUserId, true);
                }
            });
        });
        ev.cancel = promise;
    }

    onDragStart = (e: any) => {
        e.itemData = e.fromData[e.fromIndex];
    }

    onDragUserEnd = (e: any) => {
        if (e.fromComponent === e.toComponent) {
            return;
        } else if (e.toData !== e.fromData || e.toData.length === 0) {
            const userExists = this.edit.clinicUsers.find((x: any) => x.id === e.itemData.id);
            if (userExists) {
                notify('Utilizatorul este deja asociat rolului selectat.', 'warning', 3000);
                return;
            }
            this.edit.updateUserRoleList(e.itemData.id, false);
        }
    }
}

