import notify from "devextreme/ui/notify";
import { Injectable } from "@angular/core";
import { iBisEntityService } from "@dohu/ibis-entity";
import { DataService } from "../../../../service/data.service";
import { iBisLanguageService, iBisServerConfig } from "@dohu/ibis-common";
import { iBisAuthService, iBisSecureEditService } from "@dohu/ibis-auth";

@Injectable()
export class SendScheduleService extends iBisSecureEditService {

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService, private ds: DataService) {
		super(auth, entity, config, lg);
		this.title = 'Trimite listă programari';
		this.validation = 'sendScheduleValidation';
	}
	protected load(): void {
	}
	createDefault() {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error("Method not implemented.");
	}
	onRemove(id: string): Promise<void> {
		throw new Error("Method not implemented.");
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error("Method not implemented.");
	}
	reset(): void {
		this.model = {};
	}

	popupInit(ev: any) {
		super.popupInit(ev);
		ev.component.beginUpdate();
		const tbBtns = [{
			location: 'after',
			options: {
				text: 'Trimite',
				icon: 'send',
				type: 'default',
				onClick: (e: any) => {
					if (!e.validationGroup.validate().isValid) { return; }
					this.entity.execute('SendDailyAppointment', {
						userId: this.model.userId,
						partyId: this.ds.authDetails.party.id,
						startDate: this.entity.toDateFilter(this.model.startDate)
					})
						.then((result) => {
							if (result === 'OK') {
								notify('Mail trimis cu succes!', 'success', 3000);
							} else if (result === 'NO_APPOINTMENTS') {
								notify('Doctorul nu are programari in ziua selectata!', 'warning', 3000);
							} else {
								notify('Mailul nu a putut fi trimis!', 'error', 3000);
							}
						}, err => this.lg.showError(err));
				}
			},
			toolbar: 'bottom',
			widget: 'dxButton'
		}];
		ev.component.option('toolbarItems', tbBtns);
		ev.component.endUpdate();
	}
}