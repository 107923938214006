import { iBisViewComponent } from '@dohu/ibis-common';
import { DomSanitizer } from '@angular/platform-browser';
import { DxListComponent } from 'devextreme-angular/ui/list';
import { ProxyService } from '../../../../service/proxy.service';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { PatientHistoryService } from './patient-history.service';

@Component({
	selector: 'app-patient-history',
	templateUrl: './patient-history.component.html',
	styleUrls: ['patient-history.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PatientHistoryComponent extends iBisViewComponent {

	@ViewChild('patientList') patientList: DxListComponent;
	public declare edit: PatientHistoryService;
	constructor(public proxy: ProxyService, public sanitizer: DomSanitizer) {
		super(proxy.patientHistory, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onPatientChange(event: any) {
		if (event && (event.name === 'items' || event.name === 'selectedItems') && event.value) {
			if (event.value.length === 1) {
				event.value[0].p_age = this.proxy.ds.getAge(event.value[0].p_cnp);
				this.patientList.selectedItemKeys = [event.value[0].p_id];
			}
		}
	}
}
