import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';

@Injectable({
	providedIn: 'root'
})
export class EditProcedureService extends iBisSecureEditService {
	public data: CustomStore;
	public code: CustomStore;

	private spId: string;
	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService, private ds: DataService) {
		super(auth, entity, config, lg);
		this.title = this.L('PROCEDURE');
		this.validation = 'ProcedureValidation';
	}

	protected load(): void {
		this.data = this.getData();
	}

	public set specializationId(id: string) {
		this.spId = id;
		this.data = this.getData();
		this.code = this.getCode();
	}

	public get specializationId() {
		return this.spId;
	}

	createDefault(): any {
		return { hasQuantity: false };
	}
	getById(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('Procedure', id).then((obj) => {
				if (obj) {
					resolve(obj);
				} else {
					reject();
				}
			}, error => this.lg.showError(error));
		});
	}
	onRemove(id: string): Promise<void> {
		return this.entity.remove('Procedure', id);
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		return this.entity.save('Procedure', this.model.id, this.model);
	}
	reset(): void {
		this.model = this.createDefault();
	}

	loadPartySpecialization() {
		const q = new EntityQuery('Specialization').eq('isActiv', true).linkEq('id', 'specializationId', 'PartySpecialization', 'partyId', this.ds.authDetails.party.id);
		this.entity.load(q).then((spec: any) => {
			spec.data.forEach((element: any) => {
				element.text = element.description;
			});
			let partySpec = spec.data;
		})
	}

	loadUserSpecialization() {
		this.entity.load(new EntityQuery('UserSpecialization').eq('partyId', this.ds.authDetails.party.id)).then((uspec: any) => {
			let userSpec = uspec.data;
		})
	}



	private getData(): CustomStore {
		const q = new EntityQuery('Procedure').addOrderBy(['code']);
		if (this.spId) {
			q.eq('specializationId', this.spId);
		}
		return this.entity.store(q);
	}
	private getCode(): CustomStore {
		if (!this.spId) {
			return null;
		}
		const q = new EntityQuery('Procedure').eq('specializationId', this.spId).addOrderBy(['code']);
		q.fields.push('id', 'code');
		return this.entity.store(q);
	}
}
