import { Component } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EditConsultationReportService } from './edit-consultation-report.service';

@Component({
	selector: 'app-consultation-report',
	templateUrl: './consultation-report.component.html'
})
export class ConsultationReportComponent extends iBisViewComponent {

	public declare edit: EditConsultationReportService;
	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
