import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { EditPatientService } from './edit-patient.service';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-patient',
	templateUrl: './patient.component.html'
})
export class PatientComponent extends iBisViewComponent {
	@ViewChild('patientGrid') declare grid: DxDataGridComponent;
	public declare edit: EditPatientService;
	
	constructor(public proxy: ProxyService) {
		super(proxy.patient, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = '[MDS]Patient';
	}

	onPatientHistoryExpand(event: any) {
		event.component.collapseAll(-1);
	}

	toolbarPreparing(event: any) {
		super.toolbarPreparing(event, true);
		event.toolbarOptions.items.unshift({
			widget: 'dxCheckBox',
			options: {
				text: 'Pacienți fără consultații',
				onValueChanged: (e: any) => {
					this.edit.filter = e.value;
					this.refresh();
				}
			},
			location: 'before'
		});
	}
}
