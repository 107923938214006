import { Injectable } from '@angular/core';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
// iBis
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
// DevExtreme

@Injectable({
	providedIn: 'root'
})
export class EditWorkingHourService extends iBisSecureEditService {

	public data: CustomStore;
	private etId: string;
	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
		super(auth, entity, config, lg);
		this.title = 'WORKING_HOURS';
	}

	protected load(): void { }

	public set entityId(id: string) {
		this.etId = id;
	}
	
	public createDefault(): any {
		return {added: [], removed: []};
	}

	public getById(): Promise<any> {
		return new Promise((resolve, reject) => {
			const q = new EntityQuery('WorkCalendar').eq('entityId', this.etId).addOrderByDesc(['fromDate', 'thruDate']);
			this.entity.load(q).then((data: any) => {
				this.model = this.createDefault();
				data.forEach(element => {
					let obj: any = {
						id: element.id,
						range: [element.fromDate, element.thruDate],
						workStart: element.workStart,
						workEnd: element.workEnd
					};
					this.mapToDays(obj, element);
					this.model.added.push(obj);
				});
				resolve(this.model);
			});
		});
	}
	public onRemove(id: string): Promise<void> {
		return this.entity.remove('WorkCalendar', id);
	}

	public onSaveEv(serverUrl?: string): Promise<any> {
		let promises = [];
		this.model.added.forEach(m => {
			var obj = {
				id: m.id,
				entityId: this.etId,
				fromDate: new Date(new Date(m.range[0]).setHours(0, 0, 0, 0)),
				thruDate: new Date(new Date(m.range[1]).setHours(0, 0, 0, 0)),
				dayOfWeek: this.mapIntoNumber(m),
				workStart: m.workStart,
				workEnd: m.workEnd
			};
			promises.push(this.entity.save('WorkCalendar', m.id, obj));
		});
		this.model.removed.forEach(m => {
			if(m.id){
				promises.push(this.onRemove(m.id));
			}
		});
		return Promise.all(promises);
	}

	public reset(): void {
		this.model = this.createDefault();
	}

	public getData(): CustomStore {
		const q = new EntityQuery('WorkCalendar').eq('entityId', this.etId).addOrderByDesc(['fromDate', 'thruDate']);
		return this.entity.store(q, false, 'id');
	}

	private mapIntoNumber(model: any): number {
		let dayOfWeek = 0;
		if (model.monday) dayOfWeek |= 1;
		if (model.tuesday) dayOfWeek |= 2;
		if (model.wednesday) dayOfWeek |= 4;
		if (model.thursday) dayOfWeek |= 8;
		if (model.friday) dayOfWeek |= 16;
		if (model.saturday) dayOfWeek |= 32;
		if (model.sunday) dayOfWeek |= 64;
		return dayOfWeek;
	}

	private mapToDays(model: any, element: any) {
		model.monday = (element.dayOfWeek & 1) !== 0;
		model.tuesday = (element.dayOfWeek & 2) !== 0;
		model.wednesday = (element.dayOfWeek & 4) !== 0;
		model.thursday = (element.dayOfWeek & 8) !== 0;
		model.friday = (element.dayOfWeek & 16) !== 0;
		model.saturday = (element.dayOfWeek & 32) !== 0;
		model.sunday = (element.dayOfWeek & 64) !== 0;
	}
}
