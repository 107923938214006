import { Injectable } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisEntityService } from '@dohu/ibis-entity';
import { alert } from 'devextreme/ui/dialog';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { Router } from '@angular/router';
import { DataService } from '../../../app/service/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthDetailsService extends iBisEditService {

  public role: any;
  public specialization: any;
  public party: { id: string, name: string, role: any[], specialization: any[] }[];

  private ad: AuthDetails;
  constructor(private entity: iBisEntityService, public lg: iBisLanguageService, public auth: iBisAuthService, protected router: Router, public ds:DataService) {
    super(lg);
    this.model = { partyId: null, roleId: null, specializationId: null, keepOption: true };
    this.validation = 'authDetailsValidation';
    this.title = 'Configureaza utilizator';
    this.auth.onAuthFinished.subscribe(resp => {
      this.entity.execute('CheckPartyUser', {}).then(result => {
        this.ds.authDetails.party = {id: result[0].Item1, name: result[0].Item2}; 
        this.ds.authDetails.role = {id: result[0].Item3[0][0], name: result[0].Item3[0][1]};
        if(result[0].Item4.length > 0){
          this.ds.authDetails.specialization = [];
          result[0].Item4.forEach(element => {
            this.ds.authDetails.specialization.push({id: element[0], name: element[1]});
          });
        }
        this.party = [];
        result.forEach(r => {
          this.party.push({
            id: r.Item1,
            name: r.Item2,
            role: r.Item3.map((x: any) => { return { id: x[0], name: x[1] }; }),
            specialization: r.Item4.map((x: any) => { return { id: x[0], name: x[1] }; })
          });
        });

        // TODO
        // this.ad = new AuthDetails(
        //   {id: this.party[0].id, name: this.party[0].name}, 
        //   {id: this.party[0].role[0].id, name: this.party[0].role[0].name}, 
        //   {id: this.party[0].specialization[0].id, name: this.party[0].specialization[0].name});
      });
    });
  }

  public get authDetails(): AuthDetails{
    return this.ad;
  }

  onPartyChanced = (ev: any) => {
    if (ev.event || ev.value) {
      this.role = this.party.find(p => p.id == ev.value).role;
      this.specialization = this.party.find(s => s.id == ev.value).specialization
    }
  }

  onHide = () => {
    super.hidePopup();
    if (!localStorage.getItem('authDetails')) {
      this.popup.hide();
      this.auth.logout();
    }
  }

  createDefault() {
    const config = localStorage.getItem('authDetails');
    return config ? this.getDefault(JSON.parse(config)) : {};
  }
  getById(open: any, serverUrl?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const config: any = localStorage.getItem('authDetails');
      const ur = config ? JSON.parse(config) : {};
      if (!this.entity) { return; }
      // showIF keepOption is false or dont have config
      if (!ur.keepOption || !config || open) {
        if (this.party && this.party.length) {
          resolve(this.getDefault(ur));
        } else {
          this.entity.execute('CheckPartyUser', {}).then(resp => {
            resolve(this.initPartyData(resp, ur));
          });
        }
      }
    });
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    return new Promise((resolve) => {
      const obj = {
        party: this.party.find(x => x.id == this.model.partyId),
        role: this.role.find(x => x.id == this.model.roleId),
        specialization: this.specialization.find(x => x.id == this.model.specializationId),
        keepOption: this.model.keepOption
      }
      this.ds.authDetails = obj;
      this.ad.party = this.party.find(x => x.id == this.model.partyId);
      this.ad.role = this.role.find(x => x.id == this.model.roleId);
      this.ad.specialization = this.specialization.find(x => x.id == this.model.specializationId);

      localStorage.setItem('authDetails', JSON.stringify(obj));
      this.redirectTo(this.router.url);

      // TODO: Replace signalR
      // if (!this.ds.signalR.connection || (this.ds.signalR.connection && this.ds.signalR.connection.state !== 1)) {
      //   this.ds.signalR.initSignalR(this.auth.user.token);
      // }
      this.auth.displayRole = this.ds.authDetails.role.name;
      // this.auth.displayRole = this.ad.role.name;
      resolve({});
      // in functie de role fac o rutare
      // assitentul se duce pe calendar
    })
  }
  reset(): void {
    throw new Error('Method not implemented.');
  }

  // prepare party data from server
  private initPartyData(data: any, ur?: any) {
    if (data.length === 0) {
      alert('Nu exista nici o clinica asociata cu acest user.', 'Alerta').then(resp => {
        this.hidePopup();
        this.auth.logout().then(() => {
          this.router.navigateByUrl('/login');
        });
      });
      return {};
    }
    this.party = [];
    for (const el of data) {
      this.party.push({
        id: el.Item1,
        name: el.Item2,
        role: el.Item3.map(item => ({ id: item[0], name: item[1] })) || [],
        specialization: el.Item4.map(item => ({ id: item[0], name: item[1] })) || []
      });
    }
    const obj = this.getDefault(ur);
    this.onPartyChanced({ value: obj.partyId });
    return obj;

  }
  private getDefault(ur: any) {
    return {
      partyId: ur?.party?.id || this.party[0].id,
      roleId: ur?.role?.id || this.party[0].role[0].id,
      specializationId: ur?.specialization?.id || (this.party ? this.party[0]?.specialization[0]?.id : null) || null,
      keepOption: (ur?.keepOption != undefined ? ur?.keepOption : true),
    }
  }
  private redirectTo(uri: string) {
		this.router.navigateByUrl('/scheduler', { skipLocationChange: true }).then(() =>
			this.router.navigateByUrl(uri));
	}
}

export class AuthDetails {
  party: {id: string, name: string};
  role: {id: string, name: string};
  specialization: {id: string, name: string} | null;

  constructor(party: {id: string, name: string}, role: {id: string, name: string}, specialization: {id: string, name: string} | null ){
    this.party = party;
    this.role = role;
    this.specialization = specialization;
  }
}
