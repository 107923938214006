import { NgModule } from '@angular/core';
import { iBisCommonModule } from '@dohu/ibis-common';
import { UserComponent } from './user/user.component';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { CommonAppModule } from '../../common/common.module';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { PatientComponent } from './patient/patient.component';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxSortableModule } from 'devextreme-angular/ui/sortable';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { SmsQueueComponent } from './sms-queue/sms-queue.component';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { EditPatientService } from './patient/edit-patient.service';
import { ProcedureComponent } from './procedure/procedure.component';
import { iBisAuthModule, iBisEditUserService } from '@dohu/ibis-auth';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { EditPatientComponent } from './patient/edit-patient.component';
import { DiagnosticComponent } from './diagnostic/diagnostic.component';
import { EditProcedureService } from './procedure/edit-procedure.service';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { EditSendSmsService } from './sms-queue/send-sms/send-sms.service';
import { EditDiagnosticService } from './diagnostic/edit-diagnostic.service';
import { EditProcedureComponent } from './procedure/edit-procedure.component';
import { EditSendSmsComponent } from './sms-queue/send-sms/send-sms.component';
import { SpecializationComponent } from './specialization/specialization.component';
import { EditMaintenanceComponent } from './maintenance/edit-maintenance.component';
import { GeographicAreaComponent } from './geographic-area/geographic-area.component';
import { EditSpecializationService } from './specialization/edit-specialization.service';
import { EditGeographicAreaService } from './geographic-area/edit-geographic-area.service';
import { EditSpecializationComponent } from './specialization/edit-specialization.component';
import { EditGeographicAreaComponent } from './geographic-area/edit-geographic-area.component';
import { MaintenanceNotificationComponent } from './maintenance/maintenance-notification/maintenance-notification.component';
import { CompanyDetailsModule } from './company-details/company-details.module';
import { DoctorComponent } from './doctor/doctor.component';
import { EditDoctorService } from './doctor/edit-doctor.service';
import { WorkingExceptionComponent } from './doctor/working-exception/working-exception.component';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxDateRangeBoxModule } from 'devextreme-angular/ui/date-range-box';

@NgModule({
	declarations: [
		UserComponent,
		PatientComponent,
		SmsQueueComponent,
		ProcedureComponent,
		DiagnosticComponent,
		EditSendSmsComponent,
		MaintenanceComponent,
		EditPatientComponent,
		EditProcedureComponent,
		GeographicAreaComponent,
		SpecializationComponent,
		EditMaintenanceComponent,
		EditGeographicAreaComponent,
		EditSpecializationComponent,
		MaintenanceNotificationComponent,
		DoctorComponent,
		WorkingExceptionComponent
	],
	imports: [
		DxListModule,
		DxTabPanelModule,
		DxFormModule,
		DxPopupModule,
		BrowserModule,
		DxDateBoxModule,
		DxDateRangeBoxModule,
		DxButtonModule,
		iBisAuthModule,
		CommonAppModule,
		DxTextBoxModule,
		DxTextAreaModule,
		DxDataGridModule,
		DxSortableModule,
		iBisCommonModule,
		DxAccordionModule,
		DxScrollViewModule,
		CompanyDetailsModule
	],
	exports: [
		UserComponent,
		PatientComponent,
		SmsQueueComponent,
		DiagnosticComponent,
		EditPatientComponent,
		MaintenanceComponent,
		EditSendSmsComponent,
		GeographicAreaComponent,
		SpecializationComponent,
		EditGeographicAreaComponent,
		MaintenanceNotificationComponent,
		DoctorComponent,
		WorkingExceptionComponent
	],
	providers: [
		EditPatientService,
		EditSendSmsService,
		EditPatientService,
		iBisEditUserService,
		EditProcedureService,
		EditDiagnosticService,
		EditGeographicAreaService,
		EditSpecializationService,
		EditDoctorService
	]
})
export class AdminModule { }
