import { EditGeographicAreaService } from '../../geographic-area/edit-geographic-area.service';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import DataSource from 'devextreme/data/data_source';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EditPartyService extends iBisSecureEditService {

    public localities: DataSource;
    public counties: DataSource;
    public readOnly: boolean = true;

    private pId: string;

    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService, public geo: EditGeographicAreaService) {
        super(auth, entity, config, lg);
        this.validation = 'partyValidation';

        this.localities = this.getLocality();
        this.counties = this.getCounty();
    }

    public get partyId(): string {
        return this.pId;
    }

    public set partyId(id: string) {
        this.pId = id;
        this.readOnly = true;
        this.getPartyDetails();
    }

    public set countyId(id: string) {
        this.localities = this.getLocality(id);
        this.model.localityId = null;
    }

    protected load(): void {
    }
    createDefault() {
        return {};
    }
    getById(id: string): Promise<any> {
        throw new Error('Method not implemented.');
    }
    onRemove(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    onSaveEv(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.model.id) {
                this.entity.save('Party', this.model.id, this.model).then(() => {
                    this.readOnly = true;
                    this.onDataChanged.next('updated');
                    resolve('OK');
                }, error => {
                    reject(error);
                    this.lg.showError(error);
                });
            } else {
                this.model.isActiv = true;
                // TODO: Make a server Insert custom method to insert PartyUser also
                // this.entity.insert('Party', this.model).then((partyInserted) => {
                //     if(partyInserted) {
                //         this.readOnly = true;
                //         this.onDataChanged.next('inserted');
                resolve('OK');
                // }
                // }, error => {
                //     reject(error);
                //     this.lg.showError(error);
                // });
            }
        });
    }
    reset(): void {
        this.model = this.createDefault();
    }

    public cancelClinicDetails() {
        this.readOnly = true;
        this.getPartyDetails();
    }

    public disabledDates = (e: any) => {
		if (e.view === 'month') {
			this.disabledDates = (data) => {
				return data.view === 'month' && new Date(data.date).getDay() == 0;
			};
		} else {
			this.disabledDates = undefined;
		}
	}

    protected getLocality(countyId: any = null): DataSource {
        return new DataSource({ store: this.geo.getGeographic(1, countyId), paginate: true, pageSize: 50 });
    }

    protected getCounty(zoneId: any = null): DataSource {
        return new DataSource({ store: this.geo.getGeographic(2, zoneId), paginate: true, pageSize: 15 });
    }

    private getPartyDetails(): void {
        if (this.pId === '0') {
            this.reset();
        } else {
            this.entity.single(new EntityQuery('Party').eq('id', this.pId)).then((party: any) => {
                if (party) {
                    this.entity.single(new EntityQuery('GeographicArea').linkEq('id', 'parentId', 'GeographicArea', 'id', party.localityId)).then((county) => {
                        Object.assign(this.model, party);
                        this.model.countyId = county.id;
                    });
                }
            });
        }
    }
}
