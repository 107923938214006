import { Injectable } from '@angular/core';
// iBis
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
// DevExtreme
import CustomStore from 'devextreme/data/custom_store';
import { alert } from "devextreme/ui/dialog"
@Injectable({
	providedIn: 'root'
})
export class WorkingExceptionEditService {

	public data: CustomStore;

	constructor(private entity: iBisEntityService) { }

	public getData(doctorId: string): CustomStore {
		const q = new EntityQuery("WorkCalendarException").eq("doctorId", doctorId);
		return this.entity.store(q, false, 'id');
	}

	public insertNewFreeDate(doctorId: string, freeDate: Date): Promise<boolean> {
		return new Promise<any>((resolve, reject) => {
			const tr = new Date(new Date(new Date().setDate(freeDate.getDate() + 1)).setHours(0, 0, 0, 0));
			const q = new EntityQuery('Appointment').eq('userId', doctorId).in('status', [1, 2, 3])
				.gt('startDate', freeDate).lt('startDate', tr);
			this.entity.count(q).then((result: any) => {
				if (result > 0) {
					alert('<h4 style="text-align: center">Această dată are programări rezervate sau confirmate.</h4><h4 style="text-align: center">Nu puteți adăuga ziua liberă până nu reprogramați programările existente.</h4>', 'Avertisment');
					resolve(false);
				} else {
					this.entity.save("WorkCalendarException", null, { doctorId: doctorId, freeDate: freeDate })
						.then(() => resolve(true)).catch(() => reject());
				}
			});
		});
	}

	public updateNewFreeDate(id: string, doctorId: string, newFreeDate: Date): Promise<boolean> {
		return new Promise<any>((resolve, reject) => {
			const tr = new Date(new Date(new Date().setDate(newFreeDate.getDate() + 1)).setHours(0, 0, 0, 0));
			const q = new EntityQuery('Appointment').eq('userId', doctorId).in('status', [1, 2, 3])
				.gt('startDate', newFreeDate).lt('startDate', tr);
			this.entity.count(q).then((result: any) => {
				if (result > 0) {
					alert('<h4 style="text-align: center">Această dată are programări rezervate sau confirmate.</h4><h4 style="text-align: center">Nu puteți adăuga ziua liberă până nu reprogramați programările existente.</h4>', 'Avertisment');
					resolve(false);
				} else {
					this.entity.save("WorkCalendarException", id, { doctorId: doctorId, freeDate: newFreeDate })
						.then(() => resolve(true)).catch(() => reject());
				}
			});
		});
	}
}
