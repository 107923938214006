import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { EditProcedureService } from './edit-procedure.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-procedure',
	templateUrl: './procedure.component.html'
})
export class ProcedureComponent extends iBisViewComponent {
	@Input() specializationId: any = null;
	@ViewChild('gridProcedure') declare grid: DxDataGridComponent;
	public declare edit: EditProcedureService;
	constructor(public proxy: ProxyService) {
		super(proxy.procedure, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = '[MDS]Procedure';
	}

	onGridInit(ev: any) {
		this.edit.specializationId = this.specializationId;
	}

	protected prepareNew(): any {
		const result = this.edit.createDefault();
		result.specializationId = this.specializationId;
		return result;
	}
}
