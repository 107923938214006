import { WorkingExceptionEditService } from './working-exception-edit.service';
import { ProxyService } from '../../../../service/proxy.service';
import { Component, Input, ViewChild } from '@angular/core';
//iBis
import { iBisViewComponent } from '@dohu/ibis-common';
//DevExtreme
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import notify from 'devextreme/ui/notify';

@Component({
	selector: 'app-working-exception',
	templateUrl: './working-exception.component.html'
})
export class WorkingExceptionComponent extends iBisViewComponent {

	@Input() doctorId: string;
	@ViewChild('gridWorkingException') declare grid: DxDataGridComponent;

	constructor(public proxy: ProxyService, public weEdit: WorkingExceptionEditService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'workException'
	}

	public onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.weEdit.getData(this.doctorId));
		}
	}

	public onRowInserting(event: any) {
		const freeDate = this.proxy.convertToDate(new Date(event.data.freeDate));
		event.cancel = true;
		this.weEdit.insertNewFreeDate(this.doctorId, freeDate).then((response) => {
			if (response) {
				event.component.cancelEditData();
				event.component.refresh();
				notify('Zi liberă adăugată cu succes!', 'success', 3000);
			}
		}).catch((error) => { notify('Momentan, serviciul de adăugare zile libere nu este funcțional...', 'error', 3000) });
	}

	public onRowUpdating(event: any) {
		event.cancel = true;

		const newFreeDate = this.proxy.convertToDate(new Date(event.newData.freeDate));
		this.weEdit.updateNewFreeDate(event.oldData.id, this.doctorId, newFreeDate).then((response) => {
			if (response) {
				event.component.cancelEditData();
				event.component.refresh();
				notify('Zi liberă schimbată cu succes!', 'success', 3000);
			}
		}).catch((error) => { notify('Momentan, serviciul de modificare zile libere nu este funcțional...', 'error', 3000) });
	}
}
