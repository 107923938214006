import { EditWorkingHourService } from './edit-working-hour.service';
import { ProxyService } from '../../service/proxy.service';
import { Component } from '@angular/core';
// iBis
import { iBisBaseComponent } from '@dohu/ibis-common';
import { confirm } from 'devextreme/ui/dialog';
@Component({
	selector: 'app-edit-working-hour',
	templateUrl: './edit-working-hour.component.html',
	styleUrls: ['edit-working-hour.component.scss']
})
export class EditWorkingHourComponent extends iBisBaseComponent {

	constructor(public proxy: ProxyService, public edit: EditWorkingHourService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	addNewSchedule() {
		this.edit.model.added.push({
			range: [],
			startHour: null,
			endHour: null,
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false
		});
	}

	removeSchedule(data){
		confirm(this.L('CONFIRMATION_WORKING_SCHEDULE_DELETE'), this.L('ATTENTION')).then((val) => {
			if(val){
				this.edit.model.added = this.edit.model.added.filter(f => f !== data);
				this.edit.model.removed.push(data);
			}
		});

	}
}
