import { Component, NgModule } from '@angular/core';
import { ProxyService } from '../../../app/service/proxy.service';

@Component({
    selector: 'app-single-card',
    templateUrl: './single-card.component.html',
    styleUrls: ['./single-card.component.scss']
})
export class SingleCardComponent {
    constructor(public proxy: ProxyService) {
        setTimeout(() => {
            if (this.proxy.router.url === '/') {
                this.proxy.router.navigateByUrl('/login');
            }
        }, 0);
    }

    checkUrlForDxCard() {
        return (this.proxy.router.url.indexOf('/confirmInvite') > -1) || (this.proxy.router.url.indexOf('/resetPassword') > -1) ||
            (this.proxy.router.url.indexOf('/login') > -1) || (this.proxy.router.url.indexOf('/register') > -1);
    }
}

