import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { iBisAuthService, iBisResetPasswordComponent, iBisLoginComponent } from '@dohu/ibis-auth';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { PatientComponent } from './view/admin/patient/patient.component';
import { UserComponent } from './view/admin/user/user.component';
import { SpecializationComponent } from './view/admin/specialization/specialization.component';
import { CompanyDetailsComponent } from './view/admin/company-details/company-details.component';
import { MaintenanceComponent } from './view/admin/maintenance/maintenance.component';
import { SmsQueueComponent } from './view/admin/sms-queue/sms-queue.component';
import { GeographicAreaComponent } from './view/admin/geographic-area/geographic-area.component';
import { RegisterComponent } from './view/account/register/register.component';
import { UserProfileComponent } from './view/account/user-profile/user-profile.component';
import { ConsentFormComponent } from './view/medical-form/consent-form/consent-form.component';
import { HealthStateFormComponent } from './view/medical-form/health-state-form/health-state-form.component';
import { CovidFormComponent } from './view/medical-form/covid-form/covid-form.component';
import { MedicalFormComponent } from './view/medical-form/medical-form.component';
import { TermsAndConditionsComponent } from './view/account/terms/terms.component';
import { DiagnosticComponent } from './view/admin/diagnostic/diagnostic.component';
import { SchedulerComponent } from './view/scheduler/scheduler.component';
import { MessageComponent } from './view/message/message.component';
import { ConsultationReportComponent } from './view/report/consultation-report/consultation-report.component';
import { AppointmentComponent } from './view/scheduler/appointment/appointment.component';
import { OperationRoomComponent } from './view/scheduler/operation-room/operation-room.component';
import { CalendarComponent } from './view/scheduler/calendar/calendar.component';
import { DoctorComponent } from './view/admin/doctor/doctor.component';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    // { path: 'dashboard', component: DashboardComponent, canActivate: [iBisAuthService] },
    {
        path: 'scheduler', component: SchedulerComponent, children: [
            { path: 'calendar', component: CalendarComponent, canActivate: [iBisAuthService] },
            { path: 'appointment', component: AppointmentComponent, canActivate: [iBisAuthService] },
            { path: 'operation-room', component: OperationRoomComponent, canActivate: [iBisAuthService] }
        ]
    },
    // { path: 'message', component: MessageComponent, canActivate: [iBisAuthService] },
    // {
    //     path: 'report', canActivate: [iBisAuthService], children: [
    //         { path: 'consultation', component: ConsultationReportComponent }]
    // },
    { path: 'account', component: UserProfileComponent, canActivate: [iBisAuthService] },
    // { path: 'confirmInvite', component: iBisConfirmInviteComponent, data: [{ returnUrl: '/login' }] },
    {
        path: 'resetPassword',
        component: iBisResetPasswordComponent,
        data: [{ title: 'Resetează parola', isReset: true, returnUrl: '/login' }]
    },
    {
        path: 'admin', children: [
            { path: 'company-details', component: CompanyDetailsComponent, canActivate: [iBisAuthService] },
            {
                path: 'nomenclature', children: [
                    { path: 'specialization', component: SpecializationComponent, canActivate: [iBisAuthService] },
                    { path: 'diagnostic', component: DiagnosticComponent, canActivate: [iBisAuthService] },
                    { path: 'geographicareas', component: GeographicAreaComponent, canActivate: [iBisAuthService] }
                ]
            },
            { path: 'sms', component: SmsQueueComponent, canActivate: [iBisAuthService] },
            { path: 'maintenance', component: MaintenanceComponent, canActivate: [iBisAuthService] },
            { path: 'doctors', component: DoctorComponent, canActivate: [iBisAuthService] },
            { path: 'patients', component: PatientComponent, canActivate: [iBisAuthService] },
            { path: 'users', component: UserComponent, canActivate: [iBisAuthService] }

        ]
    },
    /// UNAUTH
    // {
    //     path: 'form', component: MedicalFormComponent,
    //     children: [
    //         { path: 'consent', component: ConsentFormComponent },
    //         { path: 'healthState', component: HealthStateFormComponent },
    //         { path: 'covid', component: CovidFormComponent }
    //     ]
    // },
    { path: 'register', component: RegisterComponent },
    { path: 'terms', component: TermsAndConditionsComponent },
    { path: 'login', component: iBisLoginComponent, data: [{ showRegister: true }] },
    { path: '**', redirectTo: 'login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: false })],
    providers: [iBisAuthService],
    exports: [RouterModule]
})
export class AppRoutingModule { }
