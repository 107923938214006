import notify from 'devextreme/ui/notify';
import { Component, OnInit } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends iBisViewComponent implements OnInit {

	accountData: any = {};
	storeAccountData: any = {};
	isReadOnly: boolean;
	passwordComparison: any;

	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, null);
	}

	ngOnInit() {
		this.isReadOnly = true;
		this.proxy.auth.getFullUser().then(result => {
			this.accountData = result || {};
		}, error => {
			this.proxy.lg.showError(error);
		});

		this.passwordComparison = () => {
			return this.accountData.newPassword;
		};
	}

	startEditing() {
		this.isReadOnly = false;
		Object.assign(this.storeAccountData, this.accountData);
	}

	onSaveClose() {
		this.isReadOnly = true;
		Object.assign(this.accountData, this.storeAccountData);
	}

	onSaveEdit(event: any) {
		const validation = event.instance.validate();
		if (!validation.isValid) {
			notify(this.L('FIELDS_NOT_VALID', null), 'error', 3000);
			return;
		} else if (this.accountData.newPassword === '12345') {
			notify(this.L('COMMON_PASSWORD', null), 'warning', 3000);
			return;
		}

		const obj: any = {
			fullName: this.accountData.fullName,
			emailAddress: this.accountData.emailAddress,
			phone: this.accountData.phone
		};

		if (this.accountData.newPassword && this.accountData.newPassword.length) {
			obj.correctPassword = this.accountData.newPassword.trim();
		}
		this.proxy.entity.save('UserLogin', this.proxy.auth.user.id, this.proxy.environment).then(() => {
			notify(this.L('DATA_CHANGED', null), 'success', 3000);
			this.isReadOnly = true;
		});
	}
}
