import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { EditGeographicAreaService } from './edit-geographic-area.service';

@Component({
	templateUrl: 'geographic-area.component.html'
})
export class GeographicAreaComponent extends iBisViewComponent {
	@ViewChild('geoGrid') declare grid: DxDataGridComponent;
	public declare edit: EditGeographicAreaService;
	
	constructor(public proxy: ProxyService) {
		super(proxy.geographicArea, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = '[MDS]GraphicArea';
	}
}
