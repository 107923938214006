import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { EntityQuery, EntityConditionGroup, iBisEntityService } from '@dohu/ibis-entity';
import { environment } from '../../environments/environment';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService } from '@dohu/ibis-common';
import * as sd from '../../assets/static.json';
import { Subject } from 'rxjs/internal/Subject';
import { DomSanitizer } from '@angular/platform-browser';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class DataService {
	isLoginModalVisible: boolean;
	previousUrl: any;
	static: any;
	

	authDetails = { party: { id: '', name: '' }, role: { id: '', name: '' }, specialization: [{ id: '', name: '' }] };

	public logoSubject: Subject<any> = new Subject<any>();

	constructor(public entity: iBisEntityService, public auth: iBisAuthService, public router: Router, public http: HttpClient,
		public httpClient: HttpClient, public lg: iBisLanguageService, public sanitizer: DomSanitizer) {
		this.static = sd.default;
	}

	getAge(cnp: string) {
		if (typeof cnp == 'string') {
			const yy = [1, 2].indexOf(parseInt(cnp[0], 10)) > -1 ? '19' : '20';
			const bd = new Date(parseInt(yy + cnp.substring(1, 3), 10), parseInt(cnp.substring(3, 5), 10) - 1, parseInt(cnp.substring(5, 7)));
			return Math.round((new Date().getTime() - bd.getTime()) / 1000 / 60 / 60 / 24 / 365);
		}
	}

	/* enum values */
	public getEnum(key: string, list: { 'id': number; 'value': string }[]): { 'id': number; 'value': string }[] {
		const result = [];
		const values = this.static[key] ?? list;
		values.forEach((u: { id: number; value: string }) => {
			result.push({ id: u.id, value: this.lg.format(u.value) });
		});
		return result;
	}

	getWorkingDays(partyId: string, userId?: string) {
		const q = new EntityQuery('WorkingSchedule').eq('partyId', partyId).addOrderBy(['weekDay']);
		q.eq('userId', userId ? userId : null);
		return this.entity.store(q, false, 'id').load();
	}

	getClinicWorkingSchedule(partyId: string, weekDay: number) {
		const q = new EntityQuery('WorkingSchedule').eq('partyId', partyId).eq('userId', null).eq('weekDay', weekDay);
		return this.entity.single(q);
	}

	getWorkingDaysByDoctors(doctors = []) {
		const q = new EntityQuery('WorkingSchedule').eq('partyId', this.authDetails.party.id).addOrderBy(['weekDay']);
		if (doctors && doctors.length) {
			// doctor schedule
			q.in('userId', doctors);
		} else {
			// clinic schedule
			q.eq('userId', null);
		}
		return this.entity.store(q, false, 'id');
	}

	loadWorkingDays(doctors = []) {
		const q = new EntityQuery('WorkingSchedule').eq('partyId', this.authDetails.party.id).addOrderBy(['weekDay']);
		if (doctors && doctors.length) {
			// doctor schedule
			q.in('userId', doctors);
		} else {
			// clinic schedule
			q.eq('userId', null);
		}
		return this.entity.load(q);
	}

	getPatientFiles(patientId: any, returnIds = false) {
		return new Promise((resolve) => {
			const appointments = new EntityQuery('Appointment').eq('patientId', patientId).addOrderByDesc(['startDate']);
			appointments.fields.push('id');
			this.entity.load(appointments).then((app: any) => {
				const appIds = app && app.length ? app.map(x => x.id) : [];
				appIds.push(this.auth.user.id);
				if (returnIds) { resolve(appIds); }

				const q = new EntityQuery('FileInfo').in('entityId', appIds).addOrderByDesc(['uploadTime']);
				this.entity.store(q, false, 'id', environment.saasServer).load().then((res: any) => {
					resolve(res);
				}, err => this.lg.showError(err));
			}, err => this.lg.showError(err));
		});
	}

	getFiles(entityId: string[]) {
		const q = new EntityQuery('FileInfo').in('entityId', entityId).addOrderBy(['uploadTime']);
		return this.entity.store(q, false, 'id', environment.saasServer).load();
	}

	//refactorized
	getDoctors(partyId?: string, roleId?: string) {
		return new Promise((resolve: any) => {
			const q = new EntityQuery('PartyUser').eq('partyId', partyId ?? this.authDetails.party.id).eq('roleId', roleId ?? this.authDetails.role.id);
			q.fields.push('userId');
			this.entity.store(q, false, 'id').load().then((dt: any) => {
				const ds = dt.map(d => d.userId);
				if (!ds || !ds.length) { return; }
				this.entity.store(new EntityQuery('UserLogin').in('id', ds), false, 'id', environment.saasServer).load().then(dts => {
					resolve(dts);
				}, err => this.lg.showError(err))
			});
		});
	}

	getPartyDoctors(partyId?: string) {
		return new Promise((resolve: any) => {
			const q = new EntityQuery('PartyUser').eq('partyId', partyId ?? this.authDetails.party.id);
			q.fields.push('userId');
			q.distinct = true;
			this.entity.store(q, false, 'id').load().then((dt: any) => {
				const ds = dt.map(d => d.userId);
				if (!ds || !ds.length) { return; }
				const dlink = new EntityQuery('UserLoginRole').eq('name', 'doctor').link('groupId', 'rolegroupid', new EntityQuery('ApplicationConfig')
					.linkEq('id', 'configId', 'Application', 'id', this.auth.companyId));
				const qlink = new EntityQuery('ApplicationRole').link('roleId', 'id', dlink);

				const q = new EntityQuery('UserLogin').in('id', ds).link('id', 'userId', qlink);
				q.distinct = true;
				this.entity.store(q, false, 'id', environment.saasServer).load().then((dts: any) => {
					resolve(dts);
				}, err => this.lg.showError(err))
			});
		});
	}

	loadDoctorAppointmentsByDate(doctorId: string, date: any) {
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		const q = new EntityQuery('AppointmentView').eq('ap_userId', doctorId).addOrderBy(['ap.startDate']);
		q.eq('ap_operatingRoomId', null).eq('ap_partyId', this.authDetails.party.id);
		const nextDay = new Date(date);
		nextDay.setDate(date.getDate() + 1);
		q.gte('ap.startDate', this.entity.toDateTimeFilter(date));
		q.lt('ap.startDate', this.entity.toDateTimeFilter(nextDay));
		return this.entity.store(q, false, 'ap_id').load();
	}

	returnTime(time: any) {
		if (time) {
			const h = ('0' + new Date(time).getHours()).slice(-2);
			const m = ('0' + new Date(time).getMinutes()).slice(-2);
			return h + ':' + m;
		}
	}

	getPatients(): CustomStore {
		const q = new EntityQuery('Patient').addOrderBy(['lastName']);
		const s = new DataSource({
			store: this.entity.store(q, false, 'id'),
			load: (options) => {
				const qq = q.clone();
				qq.offset = options.skip || options.userData.skip || 0;
				qq.limit = options.take || options.userData.take || 0;
				if (options.filter && options.filter.length) {
					if (options.filter[1] === 'or') {
						const value = options.filter[0][2];
						const splitValue = value.split(' ');
						const group1 = new EntityConditionGroup();
						const group2 = new EntityConditionGroup();
						const group3 = new EntityConditionGroup();
						const group4 = new EntityConditionGroup();
						// case 1: firstName + lastName or reverse order
						if (splitValue && splitValue.length > 1) {
							group1.addCondition('firstName', 7, '%' + splitValue[0] + '%', false, false);
							group1.addCondition('lastName', 7, '%' + splitValue[1] + '%', false, false);
							group2.addCondition('firstName', 7, '%' + splitValue[1] + '%', false, false);
							group2.addCondition('lastName', 7, '%' + splitValue[0] + '%', false, false);
							qq.conditionGroups.groups.push(group1);
							qq.conditionGroups.groups.push(group2);
						}
						// case 2: just firstName or lastName
						group3.addCondition('firstName', 7, '%' + value + '%', false, false);
						group3.addCondition('lastName', 7, '%' + value + '%', false, false);
						group3.useOr = true;
						qq.conditionGroups.groups.push(group3);
	
						// case 3: when 2 firstName are present + lastName or reverse order
						if (splitValue && splitValue.length === 3) {
							const subGroupStart = new EntityConditionGroup();
							subGroupStart.addCondition('firstName', 7, '%' + splitValue[0] + ' ' + splitValue[1] + '%', false, false);
							subGroupStart.addCondition('lastName', 7, '%' + splitValue[2] + '%', false, false);
							const subGroupEnd = new EntityConditionGroup();
							subGroupEnd.addCondition('firstName', 7, '%' + splitValue[1] + ' ' + splitValue[2] + '%', false, false);
							subGroupEnd.addCondition('lastName', 7, '%' + splitValue[0] + '%', false, false);
							group4.groups.push(subGroupStart);
							group4.groups.push(subGroupEnd);
							group4.useOr = true;
							qq.conditionGroups.groups.push(group4);
						}
					}
					qq.conditionGroups.useOr = true;
				}
				return this.entity.execute('FindValues', qq).then((res: any) => {
					for (const patient of res.Items) {
						patient.fullName = patient.lastName + ' ' + patient.firstName;
					}
					return { data: res.Items ? res.Items : [], totalCount: res.Count };
				});
			}
		});
		return this.entity.store(q, false, 'id');
	}

	getParty() {
		const q = new EntityQuery('Party').addOrderBy(['name']);
		return this.entity.store(q, false, 'id');
	}

	geographicAreas(typeId: number, field?: string): CustomStore {
		const q = new EntityQuery('GeographicArea').addOrderBy(['name']);
		if (typeId) {
			q.eq('typeId', typeId);
			q.fields.push('id', 'name');
		}
		return this.entity.store(q, false, field);
	}

	getLocalitiesByCountyId(countyId: string) {
		const q = new EntityQuery('GeographicArea').eq('typeId', 1).eq('parentId', countyId).addOrderBy(['name']);
		return this.entity.store(q, false, 'id');
	}

	resetValidation(component: any) {
		if (component) {
			component.reset();
			component.option('isValid', true);
		}
	}

	timeRangeOverlaps(a_start, a_end, b_start, b_end) {
		if ((a_start <= b_start && b_start < a_end)
			||
			(a_start < b_end && b_end <= a_end)
			||
			(b_start < a_start && a_end < b_end)) {
			return false;
		}
		return true;
	}
}
