import { Component } from "@angular/core";
import { iBisBaseComponent } from "@dohu/ibis-common";
import { ProxyService } from "../../service/proxy.service";

@Component({
	selector: 'app-scheduler',
	templateUrl: './scheduler.component.html'
})

export class SchedulerComponent extends iBisBaseComponent {

	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	openAppointmentPopup(data?: any) {
		this.proxy.appointment.showPopup(null);
	}

	openSendDoctorSchedulePopup() {
		this.proxy.sendSchedule.showPopup(null);
	}

	openPatientHistory() {
		if(this.proxy.calendar.isDoctor){
			this.proxy.patientHistory.showPopup([{id: this.proxy.auth.user.id}], true);
		} else {
			this.proxy.patientHistory.showPopup(this.proxy.calendar.doctorSelected, true);
		}
	}
}