import { iBisAuthModule } from '@dohu/ibis-auth';
import { BrowserModule } from '@angular/platform-browser';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { NgModule } from '@angular/core';
import { iBisCommonModule } from '@dohu/ibis-common';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { CommonAppModule } from '../../common/common.module';
import { DashboxComponent } from './dashbox/dashbox.component';
@NgModule({
	declarations: [
		DashboardComponent,
		DashboxComponent,
	],
	imports: [
		BrowserModule,
		iBisAuthModule,
		DxChartModule,
		DxPieChartModule,
		DxFormModule,
		DxPopoverModule,
		iBisCommonModule,
		CommonModule,
		CommonAppModule
	],
	exports: [
		DashboardComponent,
		DashboxComponent
	]
})
export class DashboardModule { }
