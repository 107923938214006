import { Component } from '@angular/core';
import { ProxyService } from '../../../../service/proxy.service';

@Component({
	selector: 'app-maintenance-notification',
	templateUrl: './maintenance-notification.component.html',
	styleUrls: ['./maintenance-notification.component.scss']
})
export class MaintenanceNotificationComponent {
	constructor(public proxy: ProxyService) { }

	closePopup = (ev: any) => {
		this.proxy.maintenance.isNotifyVisible = false;
		this.proxy.maintenance.notifyData = [];
	}
}
