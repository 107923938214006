import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';

@Injectable()

export class EditDoctorService extends iBisSecureEditService {

    public data: CustomStore;
    public partyData: CustomStore;

    constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService, private ds: DataService) {
        super(auth, entity, config, lg);
        this.title = 'DOCTOR';
        this.validation = 'doctorValidation';
    }

    protected load(): void {
        this.getData();
        this.getPartyData();
    }

    getById(id: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.entity.getById('Doctor', id).then((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    reject();
                }
            }, error => this.lg.showError(error));
        });
    }

    onRemove(id: string): Promise<void> {
        return this.entity.remove('Doctor', id);
    }

    onSaveEv(): Promise<any> {
        this.model.firstName = this.model.firstName.trim();
        this.model.lastName = this.model.lastName.trim();
        return this.entity.save('Doctor', this.model.id, this.model);
    }

    reset(): void {
        this.model = this.createDefault();
    }

    createDefault() {
        return { isActiv: true };
    }

    private getData() {
        const doctorRoleIdQ = new EntityQuery('UserLoginRole').eq('name', 'doctor').link('groupId', 'id', new EntityQuery('EnumValue')
            .eq('name', 'MDS').linkEq('typeId', 'id', 'EnumType', 'name', 'ROLE_GROUP'));
        this.entity.single(doctorRoleIdQ, this.config.saasServer).then((doctorRole: any) => {
            const q = new EntityQuery('PartyUser').eq('roleId', doctorRole.id);
            this.entity.load(q).then((clinicDoctors: any[]) => {
                this.data = this.entity.store(new EntityQuery('UserLogin').in('id', clinicDoctors.map(cd => cd.userId)), false, 'id', this.config.saasServer);
            });
        });
    }

    private getPartyData() {
        const doctorRoleIdQ = new EntityQuery('UserLoginRole').eq('name', 'doctor').link('groupId', 'id', new EntityQuery('EnumValue')
            .eq('name', 'MDS').linkEq('typeId', 'id', 'EnumType', 'name', 'ROLE_GROUP'));
        this.entity.single(doctorRoleIdQ, this.config.saasServer).then((doctorRole: any) => {
            const q = new EntityQuery('PartyUser').eq('roleId', doctorRole.id).eq('partyId', this.ds.authDetails.party.id);
            this.entity.load(q).then((clinicDoctors: any[]) => {
                this.data = this.entity.store(new EntityQuery('UserLogin').in('id', clinicDoctors.map(cd => cd.userId)), false, 'id', this.config.saasServer);
            });
        });
    }
}
