// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.working-hour-form-box {
  padding: 10px;
  background-color: #f0f0f0; /* Culoarea fundalului boxului */
  border-radius: 8px; /* Colțuri rotunjite pentru un aspect mai modern */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 10px rgba(255, 255, 255, 0.5); /* Umbră suplimentară pentru efect 3D */
  transform: translateY(-5px); /* Efect de ridicare */
  transition: transform 0.3s ease;
  margin-bottom: 10px;
}

.delete-button {
  position: absolute;
  right: 12px;
}

.dx-form {
  margin: 12px 0 0 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/working-hour/edit-working-hour.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,aAAA;EACA,yBAAA,EAAA,gCAAA;EACA,kBAAA,EAAA,kDAAA;EACA,oFACI,EACyC,uCAAA;EAC7C,2BAAA,EAAA,sBAAA;EACA,+BAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,oBAAA;AAAJ","sourcesContent":[".working-hour-form-box{\n    padding: 10px;\n    background-color: #f0f0f0; /* Culoarea fundalului boxului */\n    border-radius: 8px; /* Colțuri rotunjite pentru un aspect mai modern */\n    box-shadow: \n        5px 5px 15px rgba(0, 0, 0, 0.3),  /* Umbră principală */\n        -5px -5px 10px rgba(255, 255, 255, 0.5); /* Umbră suplimentară pentru efect 3D */\n    transform: translateY(-5px); /* Efect de ridicare */\n    transition: transform 0.3s ease;\n    margin-bottom: 10px;\n}\n\n.delete-button{\n    position: absolute;\n    right: 12px;\n}\n\n.dx-form {\n    margin: 12px 0 0 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
