import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery } from '@dohu/ibis-entity';

@Component({
	selector: 'app-patient-history-item',
	templateUrl: './patient-history-item.component.html'
})
export class PatientHistoryItemComponent extends iBisViewComponent implements OnChanges {

	dsDoctor: any;
	dsPatientHistory: CustomStore;

	@Input() patientId: any;
	@ViewChild('patientHistoryItemGrid') declare grid: DxDataGridComponent;

	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.proxy.ds.getDoctors().then((data: any) => { this.dsDoctor = data?.data || null; });
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.patientId && changes.patientId.currentValue) {
			const q = new EntityQuery('AppointmentView').eq('ap.patientId', changes.patientId.currentValue).eq('ap.statusId', 5).addOrderByDesc(['startDate']);
			this.dsPatientHistory = this.proxy.entity.store(q, false, 'ap_id');
		}
	}

	uploadFileEv = (rowInfo: any) => {
		this.proxy.file.upload(rowInfo.row.data.ap_id).then(() => {
			notify(this.L("FILE_UPLOADED"), 'success', 3000);
		});
	}
}
