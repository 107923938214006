import { EditPartyListService } from '../party-list/edit-party-list.service';
import { EditPartyWorkingScheduleService } from './edit-party-working-schedule.service';
import { ProxyService } from '../../../../service/proxy.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-party-edit-working-schedule',
    templateUrl: './edit-party-working-schedule.component.html'
})

export class EditPartyWorkingScheduleComponent extends iBisBaseComponent {

    constructor(public proxy: ProxyService, public edit: EditPartyWorkingScheduleService, private partyList: EditPartyListService) {
        super(null, proxy.lg, proxy.iui, proxy.activatedRoute);

        this.partyList.onDataChanged.subscribe((data) => {
            if (data === 'party-selected') {
                this.edit.partyId = this.partyList.partySelected;
            }
        })
    }

    createSchedule(schedule: any) {
        const obj = {};
        for (let i = 0; i < 7; i++) {
            const fromHourKey = 'startTime' + i;
            const toHourKey = 'endTime' + i;
            const tdy = new Date();
            const dayExists = schedule.find((x: any) => x.weekDay === i);
            const startTimeHours = dayExists && dayExists.startTime ? Number(dayExists.startTime.split(':')[0]) : 0;
            const startTimeMinutes = dayExists && dayExists.startTime ? Number(dayExists.startTime.split(':')[1]) : 0;
            const startTimeValue = new Date(tdy.getFullYear(), tdy.getMonth() + 1, tdy.getDate(), startTimeHours, startTimeMinutes, 0);
            obj[fromHourKey] = startTimeValue;

            const endTimeHours = dayExists && dayExists.endTime ? Number(dayExists.endTime.split(':')[0]) : 0;
            const endTimeMinutes = dayExists && dayExists.endTime ? Number(dayExists.endTime.split(':')[1]) : 0;
            const endTimeValue = new Date(tdy.getFullYear(), tdy.getMonth() + 1, tdy.getDate(), endTimeHours, endTimeMinutes, 0);
            obj[toHourKey] = endTimeValue;
        }
        return [obj];
    }

    onRowUpdating(ev: any) {
        const key = Object.keys(ev.newData)[0];
        const time = Object.values(ev.newData)[0];
        const day = Number(key.slice(key.length - 1));
        const keyToUpdate = key.slice(0, key.length - 1);
        const id = ev.oldData['id' + day];

        this.edit.model.id = id;
        this.edit.model[keyToUpdate] = time;

        this.edit.onSave(ev);
    }

    onRowValidating(ev: any) {

        const key = Object.keys(ev.newData)[0];
        const time = Object.values(ev.newData)[0];
        const day = Number(key.slice(key.length - 1));
        const keyToUpdate = key.slice(0, key.length - 1);
        const keyToCompare = keyToUpdate === 'startTime' ? 'endTime' : 'startTime';
        const valueToCompare = ev.oldData[keyToCompare + day];

        ev.errorText = keyToUpdate === 'startTime' ? 'Ora deschiderii nu poate fi mai mare decât ora închiderii' :
            'Ora închiderii nu poate fi mai mică decât ora deschiderii';
        ev.isValid = keyToUpdate === 'startTime' ? time < valueToCompare : time > valueToCompare;

    }
}
