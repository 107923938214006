import { Component, OnInit } from '@angular/core';
import NumberBox from 'devextreme/ui/number_box';
import { ProxyService } from '../../../../service/proxy.service';
import { iBisBaseComponent } from '@dohu/ibis-common';

@Component({
  selector: 'app-edit-consultation',
  templateUrl: './edit-consultation.component.html'
})

export class EditConsultationComponent extends iBisBaseComponent implements OnInit {

  currentProcs: any = [];
  cancelSelection = false;

  constructor(public proxy: ProxyService) {
    super(proxy.consultation, proxy.lg, proxy.iui, proxy.activatedRoute);
  }

  ngOnInit() {
  }

  setQuantityVisibile(data: any) {
    if (data && data.id && this.proxy.consultation.model.procedure && this.proxy.consultation.model.procedure.length) {
      const proc = this.proxy.consultation.model.procedure.find((id: any) => id === data.id);
      return proc != null && data.hasQuantity;
    }
    return false;
  }

  diagnosticCustomName = (rowInfo: any) => {
    return rowInfo ? ((rowInfo.code ? rowInfo.code + ' - ' : '') + rowInfo.name) : null;
  }

  clickFile(file: any) {
    this.proxy.file.view(file.id);
  }

  // TODO: Delete from appointmentDiagnostic and diagnostics when deleting from tag selected values
  onCustomItemCreatingDiagnostic = (e: any) => {
    if (this.diagnosticValidate(e)) {
      e.customItem = { name: e.text.trim(), customName: e.text.trim(), isCustom: true };
      this.proxy.entity.insert('Diagnostic', e.customItem).then((result) => {
        this.proxy.entity.insert('AppointmentDiagnostic', {appointmentId: this.proxy.consultation.model.id, diagnosticId: result.id}).then(() => {
          this.proxy.consultation.refreshDiagnostics(this.proxy.consultation.model.id);
          this.proxy.consultation.model.diagnostic.push(result.id)
        });
      });
    }
  }

  onProcedureSelCh(ev: any) {
    if (this.cancelSelection) {
      this.cancelSelection = false;
      return;
    }
    this.currentProcs = ev.component.option('selectedItems');
    if (ev.removedItems.length && ev.removedItems[0].hasQuantity) {
      this.currentProcs.push(ev.removedItems[0]);
    }
    if (ev.addedItems.length && (ev.addedItems[0].hasQuantity)) {
      const el = document.getElementById(ev.addedItems[0].id);
      const instance = NumberBox.getInstance(el) as NumberBox;
      if (instance) {
        setTimeout(() => {
          instance.focus();
        }, 300);
      }
    }
  }

  onProcedureClick(ev: any) {
    if (ev.itemData.hasQuantity === true) {
      const uniqueIds = this.currentProcs.map((p: any) => p.id)
        .filter((id: any, index: any, arr: any) => arr.indexOf(id) === index);
      ev.component.option('selectedItemKeys', uniqueIds);
      this.cancelSelection = true;
    }
  }
  
  private diagnosticValidate(e: any) {
    if (e.text === null || e.text.trim() === '') {
      return false;
    }
    this.proxy.consultation.dsDiagnostics.load().then(data => {
      for (const t of data) {
        if (t === e.text.trim()) {
          e.customItem = t;
          e.aircraftType = t;
          return false;
        }
      }
    });
    return true;
  }

}
