import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';

@Injectable()

export class EditGeographicAreaService extends iBisSecureEditService {

	public data: CustomStore;
	public zone: CustomStore;
	public county: CustomStore;
	public zoneType: { 'id': number; 'value': string }[];

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService, private ds: DataService) {
		super(auth, entity, config, lg);
		this.title = 'GEOGRAPHIC AREA';
		this.validation = 'geographicareaValidation';
	}

	protected load(): void {
		this.data = this.getData();
		this.zone = this.getGeographic(3);
		this.county = this.getGeographic(2);
		this.zoneType = this.getZoneType();
	}

	public getGeographic(typeId: number, parentId: any = null): CustomStore {
		const q = new EntityQuery('GeographicArea').eq('typeId', typeId).addOrderBy(['name']);
		if (parentId) {
			q.eq('parentId', parentId);
		}
		q.fields.push('id', 'name');
		return this.entity.store(q);
	}

	createDefault() {
		return {};
	}

	getById(id: string, serverUrl?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const q = new EntityQuery('GeographicArea').eq('id', id);
			this.entity.single(q).then(result => {
				if (!result) {
					reject();
				}
				resolve(result);
			}, err => {
				this.lg.showError(err);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('GeographicArea', id);
	}
	onSaveEv(): Promise<any> {
		return this.entity.save('GeographicArea', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		return this.entity.store(new EntityQuery('GeographicArea').addOrderBy(['name']));
	}

	private getZoneType(): { 'id': number; 'value': string }[] {
		const list = [{ 'id': 1, 'value': 'LOCALITY' }, { 'id': 2, 'value': 'COUNTY' }, { 'id': 3, 'value': 'REGION' }];
		return this.ds.getEnum('zoneType', list);
	}
}
