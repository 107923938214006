import { NgModule } from '@angular/core';
import { CompanyDetailsComponent } from './company-details.component';
import { PartyListComponent } from './party-list/party-list.component';
import { PartyDataComponent } from './party-data/party-data.component';
import { EditPartyWorkingScheduleComponent } from './edit-party-working-schedule/edit-party-working-schedule.component';
import { EditPartyUserSpecializationComponent } from './edit-party-user-specialization/edit-party-user-specialization.component';
import { EditPartyUserRoleComponent } from './edit-party-user-role/edit-party-user-role.component';
import { EditPartySpecializationComponent } from './edit-party-specialization/edit-party-specialization.component';
import { EditPartyOperatingRoomComponent } from './edit-party-operating-room/edit-party-operating-room.component';
import { EditPartyComponent } from './edit-party/edit-party.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxSortableModule } from 'devextreme-angular/ui/sortable';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { iBisCommonModule } from '@dohu/ibis-common';
import { EditPartySpecializationService } from './edit-party-specialization/edit-party-specialization.service';
import { BrowserModule } from '@angular/platform-browser';
import { CommonAppModule } from '../../../common/common.module';


@NgModule({
	declarations: [
        CompanyDetailsComponent,
		EditCompanyComponent,
		PartyListComponent,
		PartyDataComponent,
		EditPartyComponent,
		EditPartyWorkingScheduleComponent,
		EditPartyUserRoleComponent,
		EditPartyUserSpecializationComponent,
		EditPartyOperatingRoomComponent,
		EditPartySpecializationComponent,
	],
	imports: [
        DxPopupModule,
        DxButtonModule,
        DxFormModule,
        DxSortableModule,
        DxDataGridModule,
        DxAccordionModule,
        DxListModule,
		DxScrollViewModule,
        iBisCommonModule,
        BrowserModule,
		CommonAppModule
	],
	exports: [
        CompanyDetailsComponent,
		EditCompanyComponent,
		PartyListComponent,
		PartyDataComponent,
		EditPartyComponent,
		EditPartyWorkingScheduleComponent,
		EditPartyUserRoleComponent,
		EditPartyUserSpecializationComponent,
		EditPartyOperatingRoomComponent,
		EditPartySpecializationComponent,
	],
	providers: [
        EditPartySpecializationService
	]
})
export class CompanyDetailsModule { }
