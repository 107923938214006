import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { EditSpecializationService } from './edit-specialization.service';
@Component({
	selector: 'app-specialization',
	templateUrl: './specialization.component.html'
})
export class SpecializationComponent extends iBisViewComponent {
	@ViewChild('gridSpecialization') declare grid: DxDataGridComponent;

	public declare edit: EditSpecializationService;
	constructor(public proxy: ProxyService) {
		super(proxy.specialization, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = '[MDS]Specialization';
	}

	onSpectializationExpanding(event: any) {
		event.component.collapseAll(-1);
	}
}
