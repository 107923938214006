import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { EditMaintenanceService } from './edit-maintenance.service';

@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent extends iBisViewComponent {
	
	@ViewChild('gridMaintenance') declare grid: DxDataGridComponent;
	public declare edit: EditMaintenanceService;

	constructor(public proxy: ProxyService) {
		super(proxy.maintenance, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = '[MDS]Maintenance';
	}
}
