import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';

@Injectable()

export class EditSendSmsService extends iBisSecureEditService {

	public data: CustomStore;

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
		super(auth, entity, config, lg);

		this.title = 'SEND_MESSAGE';
		this.validation = 'sendSmsValidation';
	}

	protected load(): void {
		this.data = this.getData();
	}

	closePopup() {
		this.popup.hide();
		this.createDefault();
	}

	createDefault() {
		return { messageText: '' };
	}

	getById(id: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('SmsQueue', id).then(data => {
				if (data) {
					resolve(data);
				} else {
					reject();
				}
			}, error => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('SmsQueue', id);
	}

	onSaveEv(): Promise<any> {
		return new Promise((resolve) => {
			this, this.entity.execute('SendMassSMS', { message: this.model.messageText }).then(data => {
				if (data === 'OK') {
					notify('Mesajele au fost date în procesare', 'success', 3000);
					resolve(data);
				} else {
					notify('Nu s-a putut procesa trimiterea mesajelor', 'error', 3000);
				}
			}, err => {
				this.lg.showError(err);
			});
		});
	}

	reset(): void {
		this.model = this.createDefault();
	}

	public addEditConfig(id: string, val: string) {
		const obj = { appId: this.auth.companyId, key: 'AUTO_SMS', value: val };
		if (id) {
			return this.entity.update('Config',id, obj, this.config.saasServer);
		} else {
			return this.entity.insert('Config', obj);
		}
	}

	public getConfig() {
		const q = new EntityQuery('Config').eq('appId', this.auth.companyId);
		return this.entity.single(q, this.config.saasServer);
	}

	private getData(): CustomStore {
		const q = new EntityQuery('SmsQueue').eq('appId', this.auth.companyId).addOrderByDesc(['created']);
		return this.entity.store(q, false, 'id', this.config.saasServer);
	}
}
