import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { iBisFileService } from '@dohu/ibis-common';
import notify from 'devextreme/ui/notify';
import { ProxyService } from '../../service/proxy.service';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnChanges {

	@Input() modelId: string;
	@Output() uploadEv = new EventEmitter<any>();
	files: any;
	currentHover: string;
	constructor(public proxy: ProxyService, public sanitizer: DomSanitizer) { }

	ngOnChanges(_changes: SimpleChanges): void {
		this.initFiles();
	}

	initFiles() {
		this.files = [];
		this.proxy.ds.getFiles([this.modelId]).then((response: any) => {
			this.files = response.data;
			for (const file of this.files) {
				this.proxy.file.getLastObjectURL(file.id).then(data => {
					file.previewUrl = data ? this.sanitizer.bypassSecurityTrustResourceUrl(data) : null;
				});
			}
		});
	}

	getFile(id: string, type: boolean) {
		if (type) {
			this.proxy.file.download(id);
		} else {
			this.proxy.file.view(id);
		}
		return false;
	}

	uploadFile = (file: any) => {
		this.proxy.file.upload(this.modelId, file.name).then(_resp => {
			this.uploadEv.emit(true);
			notify('Success', 'success', 3000);
			this.initFiles();
		}, err => {
			notify(err, 'error', 300);
		});
	};

	deleteFile(id: string) {
		this.proxy.file.deleteFile(id).then(_dr => {
			notify('Success', 'success', 3000);
			this.files = this.files.filter(x => x.id !== id);
		});
	}

	checkFormat(f: any, includePDF: boolean = true) {
		let format = /.(jpg|jpeg|png|gif)$/i;
		if(includePDF){ format = /.(jpg|jpeg|png|gif|pdf)$/i 
		}
		if (!f.name.match(format)) {
			return false;
		}
		return true;
	}
}
