import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class EditSpecializationService extends iBisSecureEditService {
	public data: CustomStore;
	public calendarData: CustomStore;

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
		super(auth, entity, config, lg);
		this.title = 'SPECIALIZATION';
		this.validation = 'SpecializationValidation';
	}
	protected load(): void {
		this.data = this.getData();
		this.calendarData = this.getCalendarData();
	}
	createDefault() {
		return { isActiv: true };
	}
	getById(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('Specialization', id).then((obj) => {
				if (obj) {
					resolve(obj);
				} else {
					reject();
				}
			}, error => this.lg.showError(error));
		});
	}
	onRemove(id: string): Promise<void> {
		return this.entity.remove('Specialization', id);
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		return this.entity.save('Specialization', this.model.id, this.model);
	}
	reset(): void {
		this.model = this.createDefault();
	}
	private getData(): CustomStore {
		return this.entity.store(new EntityQuery('Specialization').addOrderBy(['code']));
	}

	private getCalendarData(): CustomStore {
		// TODO: make linkEq, and eq with partyId => ds.authDetails.party.id;
		const q = new EntityQuery('Specialization', false, true).addOrderBy(['code']);
		q.link('id', 'specializationId', new EntityQuery('UserSpecialization'));
		return this.entity.store(q);
	}
}
