import { Injectable } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { EntityQuery, EntityConditionGroup, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisServerConfig } from '@dohu/ibis-common';
import validationEngine from 'devextreme/ui/validation_engine';
import { environment } from '../../../../environments/environment';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { iBisAuthService, iBisSecureService } from '@dohu/ibis-auth';
@Injectable()

export class OperationRoomService extends iBisSecureService {

	public roomSelected = [];
	data: any;

	private cv: string = 'day';
	private sd: Date = new Date();

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, public ds: DataService) {
		super(auth, entity, config);
	}

	protected load(): void {
		this.data = this.getData();
	}

	set currentView(cv: string) {
		this.cv = cv;
	}

	get currentView(): string {
		return this.cv;
	}

	set selectedDate(selectedDate: Date) {
		this.sd = selectedDate;
	}

	get selectedDate(): Date {
		return this.sd;
	}
	
	public refresh() {
		this.data = this.getData();
	}

	private getData() {
		
		const q = new EntityQuery('AppointmentView').addOrderBy(['startDate']);

		switch (this.cv) {
			case 'day':
				const fday = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), 0, 0, 0, 0);
				const tday = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate() + 1, 0, 0, 0, 0);
				q.gte('startDate', this.entity.toDateTimeFilter(fday)).lte('startDate', this.entity.toDateTimeFilter(tday));
				break;
			case 'week':
				break;
			case 'month':
				const fmonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1, 0, 0, 0, 0);
				const tmonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0, 0, 0, 0);
				q.gte('startDate', this.entity.toDateTimeFilter(fmonth)).lte('startDate', this.entity.toDateTimeFilter(tmonth));
				break;
			default:
				break;
		}
		if (this.roomSelected.length > 0) {
			q.in('ap.userId', this.roomSelected.map(x => x.id));
		}

		return this.entity.store(q, false, 'ap_id');
	}

	getOperatingRoom(ids?: string[], partyId?: string) {
		const q = new EntityQuery('OperatingRoom').addOrderBy(['name']);
		q.eq('partyId', partyId || this.ds.authDetails.party.id);
		if (ids && ids.length > 0) {
			q.in('id', ids);
		}
		return this.entity.store(q, false, 'id');
	}
}

