import { EditPartyService } from '../edit-party/edit-party.service';
import { EditPartyListService } from './edit-party-list.service';
import { ProxyService } from '../../../../service/proxy.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-party-list',
    templateUrl: './party-list.component.html',
    styleUrls: ['./party-list.component.scss']
})

export class PartyListComponent extends iBisBaseComponent {

    constructor(public proxy: ProxyService, public edit: EditPartyListService, private editParty: EditPartyService) {
        super(null, proxy.lg, proxy.iui, proxy.activatedRoute);

        this.editParty.onDataChanged.subscribe((data) => {
            if (data === 'inserted') {
                this.edit.refresh(true);
            } else if (data === 'updated') {
                this.edit.refresh(false, true);
            }
        })
    }

    onPartyChangedEv(event: any): void {
        this.edit.partySelected = event.addedItems[0].id;
    }

    onListInit(event: any): void {
        this.edit.listInstance = event.component;
    }
}