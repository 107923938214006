import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EditPatientService } from './edit-patient.service';

@Component({
	selector: 'app-edit-patient',
	templateUrl: './edit-patient.component.html'
})
export class EditPatientComponent extends iBisBaseComponent {

	public edti: EditPatientService;
	constructor(public proxy: ProxyService) {
		super(proxy.patient, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
